<script>
  import { onMount } from "svelte";
  import supabase from "$lib/services/db";
	import { createEventDispatcher } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";

  export let props = null;
  export let adminView = false;
  export let match = null;
  const dispatch = createEventDispatcher();

  let odds;
  let oddsByLeague = {};

  let markets = {
    "Match Winner": {
      "betway": {
        "India": {
          "probability": "74.32",
          "odds": "1.25"
        },
        "Australia": {
          "probability": "25.68",
          "odds": "3.80"
        }
      }
    },
    "Toss Winner": {
      "betway": {
        "India": {
          "probability": "50",
          "odds": "1.91"
        },
        "Australia": {
          "probability": "50",
          "odds": "1.91"
        }
      }
    }
  }
  if (match && match.odds) {
    for(let o of match.odds) {
      for(const [bookmaker, bets] of Object.entries(o)) {
        for(const [market, selections] of Object.entries(bets)) {
          if(!markets[market]) {
            markets[market] = {}
          }
          markets[market][bookmaker] = selections[selections.length-1]
          let totalOdds = 0
          for(let [key, value] of Object.entries(markets[market][bookmaker])) {
            if(key === "timestamp") continue
            value.odds = parseFloat(value.odds).toFixed(2)
            totalOdds += 1/value.odds
          }
          for(let [key, value] of Object.entries(markets[market][bookmaker])) {
            if(key === "timestamp") continue
            value.probability = ((100 / value.odds) / totalOdds).toFixed(2)
          }
        }
      }
    }
  }

  const load = async () => {
    const { data, error } = await supabase
      .from("odds")
      .select(`
        name,
        league,
        starts_at,
        onexbet,
        betway,
        match: match_id (
          id,
          starts_at,
          home_team: team_a_id (
            name,
            code
          ),
          away_team: team_b_id (
            name,
            code
          ),
          sport_seasons!sport_matches_season_id_fkey (
            id,
            name,
            tournament_id (
              name
            )
          )
        )
      `)
    .eq("type", "match")
    .gt("starts_at", new Date().toISOString())
    .order("league")
    .order("starts_at")
    console.log(error)
    odds = data

    odds.forEach(o => {
      o.startsAt = new Date(o.starts_at)
      if(o.match) {
        o.home = o.match.home_team.name
        o.away = o.match.away_team.name
        o.league = o.match.sport_seasons.name

      }
      else {
        const [home, away] = o.name.split(' - ')
        o.home = home
        o.away = away
      }
      if(!Array.isArray[o.league]) oddsByLeague[o.league] = []
      oddsByLeague[o.league].push(o)
    })
    console.log(oddsByLeague)
  }

  onMount(async () => {
    await load()
  })
</script>

{#if adminView}
<Group colspan="4-8" addClass="form-item">
  <Input
    label="Betting Tips"
    type="select"
    bind:value={props.oddsListType}
    on:change={() => dispatch("keyup")}>
    <option value="" selected disabled hidden>~not set~</option>
    <option value="slider">Slider</option>
    <option value="list">List</option>
  </Input>
</Group>
{:else}
  {#if props.oddsListType == 'slider' && match}
    <div class="block block-betting-odds">
      <div class="block-header">
        <div class="container">
          <div class="grid">
            <div class="g-col-12">
              <h2>Betting Odds</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="tip-odds">
              <div class="row">
                <div class="odds match-winner">
                  <h4>Match Winner</h4>
                  <span class="home-team">{ match.home_team.name }</span>
                  <span class="form">
                    <span class="loss">L</span>
                    <span class="loss">L</span>
                    <span class="draw">D</span>
                    <span class="win">W</span>
                    <span class="loss">L</span>
                  </span>
                  <span class="away-team">{ match.away_team.name }</span>
                  <span class="form">
                    <span class="win">W</span>
                    <span class="win">W</span>
                    <span class="draw">D</span>
                    <span class="win">W</span>
                    <span class="loss">L</span>
                  </span>
                  <div class="prediction-wizard">
                    <div class="home-win">
                      {#if markets["Match Winner"]}
                        <span>{markets["Match Winner"]["betway"][match.home_team.name]["probability"]}%</span>
                      {/if}
                      <span>{match.home_team.code}</span>
                    </div>
                    {#if markets["Match Winner"] && markets["Match Winner"]["betway"]["Draw"]}
                      <div class="draw-win">
                        <span>{markets["Match Winner"]["betway"]["Draw"]["probability"] || "50"} %</span>
                        <span>DRAW</span>
                      </div>
                    {/if}
                    <div class="away-win">
                      {#if markets["Match Winner"]}
                        <span>{markets["Match Winner"]["betway"][match.away_team.name]["probability"]}%</span>
                      {/if}
                      <span>{match.away_team.code}</span>
                    </div>
                  </div>
                  <div class="bet-odds">
                    <a href={null} class="home-win">
                      <span>Home</span>
                      {#if markets["Match Winner"]}
                        <span>{markets["Match Winner"]["betway"][match.home_team.name]["odds"]}</span>
                      {/if}
                    </a>
                    {#if markets["Match Winner"] && markets["Match Winner"]["betway"]["Draw"]}
                      <a href={null} class="draw-win">
                        <span>Draw</span>
                        <span>{markets["Match Winner"]["betway"]["Draw"]["odds"]}</span>
                      </a>
                    {/if}
                    <a href={null} class="away-win">
                      <span>Away</span>
                      {#if markets["Match Winner"]}
                        <span>{markets["Match Winner"]["betway"][match.away_team.name]["odds"]}</span>
                      {/if}
                    </a>
                  </div>
                </div>
                <div class="odds toss-winner">
                  <h4>Coin Toss</h4>
                  <span class="home-team">{match.home_team.name}</span>
                  <span class="form">
                    <span class="loss">L</span>
                    <span class="loss">L</span>
                    <span class="loss">L</span>
                    <span class="win">W</span>
                    <span class="win">W</span>
                  </span>
                  <span class="away-team">{match.away_team.name}</span>
                  <span class="form">
                    <span class="win">W</span>
                    <span class="win">W</span>
                    <span class="loss">L</span>
                    <span class="win">W</span>
                    <span class="loss">L</span>
                  </span>
                  <div class="prediction-wizard">
                    <div class="home-win">
                      {#if markets["Toss Winner"]}
                      <span>{markets["Toss Winner"]["betway"][match.home_team.name]["probability"]}%</span>
                      {/if}
                      <span>{match.home_team.code}</span>
                    </div>
                    <div class="away-win">
                      {#if markets["Toss Winner"]}
                      <span>{markets["Toss Winner"]["betway"][match.away_team.name]["probability"]}%</span>
                      {/if}
                      <span>{match.home_team.code}</span>
                    </div>
                  </div>
                  <div class="bet-odds">
                    <a href={null} class="home-win">
                      <span>Home</span>
                      {#if markets["Toss Winner"]}
                      <span>{markets["Toss Winner"]["betway"][match.home_team.name]["odds"]}</span>
                      {/if}
                    </a>
                    <a href={null} class="away-win">
                      <span>Away</span>
                      {#if markets["Toss Winner"]}
                      <span>{markets["Toss Winner"]["betway"][match.home_team.name]["odds"]}</span>
                      {/if}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else if props.oddsListType == 'list'}
    <div class="odds-list">
      <h2>Cricket Betting Odds</h2>

      <div class="row">

        {#each Object.entries(oddsByLeague) as [key, val]}
        <div class="event-tournament">
        <h4><a href={null}>
          <img alt={key} src={
          `https://res.cloudinary.com/qm-media/image/upload/f_auto,w_100/varial/ocb/logos/tournaments/${key.toLowerCase().replaceAll(" ", "-")}`
        }>
          <span>{key}</span>
        </a></h4>
        {#each val as match}
        <div class="event-match">
          <a href={null} class="event-info">
            <span class="home">{match.home}</span>
            <span class="away">{match.away}</span>
            <span class="event-start">{String(match.startsAt.getHours()).padStart("2", "0")}:{String(match.startsAt.getMinutes()).padStart("2", "0")}</span>
          </a>
          <div class={`event-odds ${match.betway && match.betway["Match Winner"] && match.betway["Match Winner"][match.betway["Match Winner"].length - 1]["Draw"]} ? "_3-way" : "_2-way"}`}>
            <span class="home">{match.betway && match.betway["Match Winner"] ? match.betway["Match Winner"][match.betway["Match Winner"].length - 1][match.home]['odds'] : 0}</span>
            {#if match.betway && match.betway["Match Winner"] && match.betway["Match Winner"][match.betway["Match Winner"].length - 1]["Draw"]}
            <span class="draw">{match.betway && match.betway["Match Winner"] ? match.betway["Match Winner"][match.betway["Match Winner"].length - 1]["Draw"]['odds'] : 0}</span>
            {/if}
            <span class="away">{match.betway && match.betway["Match Winner"] ? match.betway["Match Winner"][match.betway["Match Winner"].length - 1][match.away]['odds'] : 0}</span>
          </div>
        </div>
        {/each}
        </div>
        {/each}

        <a href={null} class="view-more">View more odds</a>
      </div>
    </div>
  {:else}
    <p>OddsListType not set..</p>
  {/if}
{/if}

<style>
  .block-betting-odds {
    background: #11171d;
  }
  .tip-odds .row {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: flex-start;
    padding: 1rem;
  }
  .block-header {
    background: var(--clr-pri);
    padding: 1rem 0;
  }
  .block-header h2 {
    color: #fff;
    font-size: 1rem;
    margin: 0;
  }
  .block-betting-odds > .container {
    padding: 0;
  }
  .tip-odds .odds {
    width: 300px;
    padding: 1rem;
    color: #fff;
    background: #15202c;
    border: 1px solid #1e2a3a;
    border-radius: var(--bd-rad);
    font-size: 0.75rem;
    display: grid;
    gap: 0.5rem;
    align-items: center;
    grid-template-areas:
      "header header"
      "home-team home-form"
      "away-team away-form"
      "wizard wizard"
      "odds odds";
  }
  .tip-odds .odds h4 {
    font-size: 0.75rem;
    color: #fff;
    text-transform: none;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
    grid-area: header;
  }
  .tip-odds .odds .form {
    display: flex;
    justify-self: flex-end;
    align-items: center;
    gap: 0.25rem;
  }
  .tip-odds .odds .form span {
    padding: 0.15rem;
    background-color: #f6f6f6;
    border-radius: 4px;
    color: #222;
    font-weight: bold;
    display: grid;
    place-content: center;
    font-size: 0.5rem;
    width: 1.1rem;
    height: 1.1rem;
  }
  .tip-odds .odds .form span:last-of-type {
    font-size: 0.65rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .tip-odds .odds .form span.loss {
    background: #ffa2a2;
    color: red;
  }
  .tip-odds .odds .form span.win {
    background: #5cea5c;
    color: green;
  }
  .tip-odds .odds .prediction-wizard {
    display: grid;
    grid-auto-flow: column;
    margin: 1rem 0;
    grid-area: wizard;
    gap: 0.25rem;
  }
  .tip-odds .odds .prediction-wizard > div {
    display: grid;
    place-content: center;
    text-align: center;
    background: #151d26;
    border-radius: 4px;
    padding: 0.5rem;
    transition: all ease-in-out .2s;
  }
  .tip-odds .odds .prediction-wizard > div:hover {
    background: #243041;
  }
  .tip-odds .odds .prediction-wizard > div span:first-of-type {
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  .tip-odds .odds .prediction-wizard > div span:last-of-type {
    font-size: 0.65rem;
  }
  .tip-odds .odds .bet-odds {
    display: grid;
    grid-auto-flow: column;
    gap: 0.25rem;
    grid-area: odds;
  }
  .tip-odds .odds .bet-odds a {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
    color: #222;
    background: #ddd;
    border-radius: 4px;
    padding: 0.35rem 0.5rem;
    font-size: 0.65rem;
    font-weight: 700;
  }
  .tip-odds .odds .bet-odds a:hover {
    background: #ccc;
  }

  .odds-list {
    margin: 0 0 1rem 0;
  }
  .odds-list h2 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 1rem 0;
    background: #232323;
    color: #fff;
    padding: 1rem;
  }
  .event-tournament {
    margin: 0 0 1rem 0;
  }
  .event-tournament h4 {
    margin: 2rem 0 0.5rem 0;
    font-size: 0.8rem;
    width: 100%;
  }
  .event-tournament:first-of-type h4 {
    margin: 0 0 0.5rem 0;
  }
  .event-tournament h4 a {
    color: #222;
    display: flex;
    align-items: center;
  }
  .event-tournament h4 a img {
    width: 25px;
    border-radius: 4px;
    margin: 0 0.5rem 0 0;
  }
  .event-tournament .event-match {
    padding: 0.5rem 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    background: #ebebeb;
    padding: 0.5rem;
    border-radius: 4px;
    margin: 0 0 0.5rem 0;
  }
  .event-tournament .event-match .event-info {
    display: grid;
    font-size: 0.65rem;
    color: #222;
  }
  .event-tournament .event-match .event-info .home,
  .event-tournament .event-match .event-info .away {
    font-weight: 600;
    font-size: 0.7rem;
  }
  .event-tournament .event-match .event-info .event-start {
    color: #999;
  }
  .event-tournament .event-match .event-info .event-start.live {
    color: red;
    font-weight: 600;
    text-transform: uppercase;
  }
  .event-tournament .event-match .event-odds {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
  }
  .event-tournament .event-match .event-odds._2-way {
    grid-template-columns: repeat(2, 1fr);
  }
  .event-tournament .event-match .event-odds._3-way {
    grid-template-columns: repeat(3, 1fr);
  }
  .event-tournament .event-match .event-odds span {
    background: #ddd;
    cursor: pointer;
    color: #222;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    display: grid;
    place-content: center;
  }
  .event-tournament .event-match .event-odds span:hover {
    background: #bbb;
  }
  .odds-list a.view-more {
    color: #222;
    font-weight: 600;
    font-size: 0.75rem;
    text-align: right;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 1rem 0;
  }
  .odds-list a.view-more:after {
    color: #222;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23222' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    line-height: 0;
    padding: 0 0.25rem;
    transition: all ease-in-out .2s;
  }
  .odds-list a.view-more:hover:after {
    padding: 0 0.1rem 0 0.4rem;
  }
</style>