<script>
  import supabase from "$lib/services/db";
  import { project } from "$lib/services/store";
  import { onMount } from "svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Manage from "$lib/admin/components/Manage.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import Icons from "$lib/main/components/Icons.svelte";

  export let allowDeletes = false; // allow deleting images inside browser
  export let imageSelectorCallback = null; // Adds selector button, passing back selected image

  let images = []; // List[{id: string, name: string, updated_at: string, metadata: Object}]

  // State used within `listImages`; updated by UI
  let searchState = {
    offset: 0,
    limit: 10,
    sortBy: { column: "name", order: "asc" },
    search: null,
  };
  let message = null;  // Manage component message

  let currentImage = null; // Current image for Modal inspections
  let toggleShowDetails = false; // Modal for showing image details
  let toggleShowImage = false; //  Model for showing bigger image

  const projectKey = $project.key;

  let currentFolder = $project.key;

  $: images;


  // List images from supabase using current `searchState`
  const listImages = async () => {
    const { data, error: err } = await supabase.storage
      .from("content")
      .list(currentFolder, {
        limit: searchState.limit,
        offset: searchState.offset,
        sortBy: searchState.sortBy,
        search: searchState.search,
      });
    if (err) {
      console.error(err);
      message = {
        success: false,
        display: "Failed to list images, try again later",
      };
      return;
    }
    data.forEach(async (img) => {
      img.url = await getImageURL(img.name);
    });
    return data;
  };

  const deleteImage = async (name) => {
    // TODO: Deleting images here required the supabase client made with
    // `PRIVATE_SUPABASE_SERVICE_ROLE` which can only be on server-side.
    message = {
      success: false,
      display: "Deleting images not yet implemented.",
    };
  };

  // Get image URL from an image name, uses current project key
  const getImageURL = async (name) => {
    const { data, error } = await supabase.storage
      .from("content")
      .getPublicUrl(`${currentFolder}/${name}`);

    if (error) {
      console.error(error);
      message = { success: false, display: "Failed to fetch image: " + name };
      return;
    }
    return data.publicUrl;
  };

  // Call and assign `images` from `listImages`
  const updateImages = async () => {
    images = await listImages();
  };

  // Get initial list
  onMount(updateImages);
</script>

<Manage {message} />

<div class="main-content sidebar image-browser">
  <div class="header">
    <h2>Image inventory</h2>
    {#if currentFolder.includes("/")}
      <button on:click={() => {currentFolder = currentFolder.split("/").slice(0, currentFolder.split("/").length - 1).join("/"); updateImages();}}><Icons name="corner-left-up" strokeWidth="2" />{ currentFolder = currentFolder.split("/").slice(0, currentFolder.split("/").length - 1).join("/") }</button>
    {/if}
  </div>

  <div class="content">
    <div class="section only-table media">
      <table class="image-list">
        <thead>
          <tr><th>Name</th><th>Thumbnail</th><th>Manage</th></tr>
        </thead>

        <tbody>
          {#if images.length}
            {#each images as image}
              <tr>
                {#if !image.id}
                  <td colspan="3"><button class="folder" on:click={() => {currentFolder += "/" + image.name; updateImages();}}><Icons name="folder" strokeWidth="2" />{ image.name }</button></td>
                {:else}
                <td>
                  <Input value={image.name} disabled={true} />
                </td>
                <td>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <img
                    src={image.url}
                    width="35"
                    height="35"
                    alt={image.url}
                    on:click={() => {
                      currentImage = image;
                      toggleShowImage = !toggleShowImage;
                    }}
                  />
                </td>
                <td>
                  <Button
                    addClass="tertiary hollow"
                    on:click={() => {
                      toggleShowDetails = true;
                      currentImage = image;
                    }}
                  >
                    Details
                  </Button>
                  {#if imageSelectorCallback}
                    <Button
                      addClass="primary hollow"
                      on:click={async () => {
                        if (imageSelectorCallback) imageSelectorCallback(image);
                      }}
                    >
                      Select
                    </Button>
                  {/if}
                  {#if allowDeletes}
                    <Button
                      addClass="secondary hollow "
                      on:click={() => deleteImage(image.name)}
                    >
                      Delete
                    </Button>
                  {/if}
                </td>
                {/if}
              </tr>
            {/each}
          {:else}
            <tr>
              <td colspan="3">No images found..</td>
            </tr>
          {/if}
        </tbody>

        <tfoot>
          <tr>
            <!-- Back and forward paging buttons -->
            <td colspan="3" class="pagination">
              <div class="pagination-container">
                <div class="pagination-state">Showing images <span>{searchState.offset + 1}</span> to <span>{searchState.offset +
                  images.length}</span></div>

                <div class="pagination-navigation">
                  <Button
                    addClass={searchState.offset === 0 ? "disabled" : "tertiary"}
                    disabled={searchState.offset === 0}
                    on:click={async () => {
                      searchState.offset =
                        searchState.offset - searchState.limit <= 0
                          ? 0
                          : searchState.offset - searchState.limit;
                      await updateImages();
                    }}
                  >‹</Button>
                  <!-- TODO: fix so disabled actually work -->
                  <Button
                    addClass={searchState.offset > images.length ? "disabled" : "tertiary"}
                    disabled={searchState.limit > images.length}
                    on:click={async () => {
                      searchState.offset = searchState.offset + searchState.limit;
                      await updateImages();
                    }}>›</Button>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <!-- Show image details -->
      <Modal bind:toggleModal={toggleShowDetails}>
        <svelte:fragment slot="header">Image Details</svelte:fragment>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{currentImage.name}</td>
            </tr>
            <tr>
              <td>Size (kb)</td>
              <td>{Math.round(currentImage.metadata.size / 1000)}</td>
            </tr>
            <tr>
              <td>Created</td>
              <td>{currentImage.created_at}</td>
            </tr>
            <tr>
              <td>URL</td>
              <td>
                <Group colspan="1">
                  <Input value={currentImage.url} disabled={true} />
                </Group>
              </td>
            </tr>
            <tr>
              <td>MimeType</td>
              <td>{currentImage.metadata.mimetype}</td>
            </tr>
          </tbody>
        </table>
      </Modal>

      <!-- Show original image size -->
      <Modal bind:toggleModal={toggleShowImage}>
        <svelte:fragment slot="header">Original Image</svelte:fragment>
        <img src={currentImage.url} alt="" width="100%" />
      </Modal>
    </div>
  </div>

  <div class="sidebar">
    <div class="section">
      <h4>Filters</h4>
      <Group addClass="form-item" colspan="1">
        <Input
          placeholder="Search text to filter files by.."
          bind:value={searchState.search}
          on:keyup={async () => await updateImages()} />
        <Input
          id="sort-by"
          type="select"
          on:change={async () => {
            let sel = document.getElementById("sort-by");
            searchState.sortBy.column = sel.options[sel.selectedIndex].value;
            await updateImages();
          }}>
          <option value={null} disabled selected hidden> Sort by</option>
          <option value="name">Name</option>
          <option value="created_at">Created</option>
          <option value="last_accessed_at">Last accessed</option>
        </Input>
        <Input
          id="page-size"
          type="select"
          on:change={async () => {
            let sel = document.getElementById("page-size");
            searchState.limit = sel.options[sel.selectedIndex].value;
            await updateImages();
          }}>
          <option value={null} disabled selected hidden>Results per page</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </Input>
      </Group>
    </div>
  </div>
</div>