<script>
  import { project, orgOperators } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";
	import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;
  const dispatch = createEventDispatcher();

  let hidden = true;

  // TEMP:
  let dummyData = {
    "bse": {
      "tips": [
        {
          "sport": "soccer",
          "startDate": "2023-02-01",
          "box": "today",
          "startTime": "18:00",
          "bet": "AaB - Viborg",
          "outcome": "2",
          "odds": "2.25",
          "operator": 19,
          "user": "user",
          "rating": 3
        },
        {
          "sport": "tennis",
          "startDate": "2023-02-01",
          "box": "today",
          "startTime": "18:00",
          "bet": "Juan Manuel Cerundolo - Dusan Lajovic",
          "outcome": "2",
          "odds": "1.57",
          "operator": 21,
          "user": "user",
          "rating": 2
        },
        {
          "sport": "soccer",
          "startDate": "2023-02-01",
          "box": "today",
          "startTime": "21:00",
          "bet": "Real Madrid lyftar bucklan",
          "outcome": "Ja",
          "odds": "3.00",
          "operator": 19,
          "user": "expert",
          "rating": 3
        },
        {
          "sport": "soccer",
          "startDate": "2023-02-02",
          "box": "tomorrow",
          "startTime": "20:30",
          "bet": "Borussia Dortmund - RB Leipzig",
          "outcome": "Över 1.0 mål första halvleken",
          "odds": "1.63",
          "operator": 4,
          "user": "expert",
          "rating": 2
        }
      ]
    }
  }

  function toggle(event) {
    let analysisHTML = ""
    if (props.bettingTipsType == 'event') {
      analysisHTML = event.currentTarget.nextElementSibling
    }
    if (props.bettingTipsType == 'content') {
      analysisHTML = event.currentTarget.querySelector('.tip-analysis')
    }

    const analysis = analysisHTML
    const open = event.currentTarget.getAttribute("aria-expanded")
    const setValue = open === "true" ? "false" : "true"
    event.currentTarget.setAttribute("aria-expanded", setValue)
    if(open === "true") {
      analysis.setAttribute("hidden", "")
    } else {
      analysis.removeAttribute("hidden")
    }
  }
</script>

{#if adminView}
  <Heading adminView {props} on:keyup />
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Betting Tips"
      type="select"
      bind:value={props.bettingTipsType}
      on:change={() => dispatch("keyup")}>
      <option value="" selected disabled hidden>~not set~</option>
      <option value="event">Event</option>
      <option value="content">In-content</option>
    </Input>
  </Group>
{:else}
  {#if props.bettingTipsType == "event"}
    <div class="block block-betting-tips">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="betting-tips-holder">
              <Heading {props} />
              <div class="tip highlight">
                <span class="tip-outcome">England to win the match</span>
                <div class="tip-analysis">
                  <span class="tip-rating">
                    <Icons name="star" fill="#15202c" />
                    <Icons name="star" fill="#15202c" />
                    <Icons name="star" />
                  </span>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <span class="tip-reasoning" aria-expanded={!hidden} on:click={toggle}>Analysis</span>
                  <p {hidden}>England is in excellent form at the moment and we think they will keep up their momentum in their next set of games against India. Stokes will fire on all cylinders.</p>
                  <div class="place-bet">
                    <img alt="Betway" class="_betway" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/betway-icon">
                    <span class="returns">₹1,000 Bet - Returns ₹1,800</span>
                    <a href={null}>1.80</a>
                  </div>
                </div>
              </div>
              <div class="tip">
                <span class="tip-outcome">Ben Stokes to get 1+ Wicket</span>
                <div class="tip-analysis">
                  <span class="tip-rating">
                    <Icons name="star" fill="#15202c" />
                    <Icons name="star" />
                    <Icons name="star" />
                  </span>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <span class="tip-reasoning" aria-expanded={!hidden} on:click={toggle}>Analysis</span>
                  <p {hidden}>Ben Stokes is not the best bowler in the England squad, but he has shown good form recently. With the odds at 3.75 we like to take a small punt on him hitting the stumps in the next match.</p>
                  <div class="place-bet">
                    <img alt="Dafabet" class="_dafabet" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/dafabet-icon">
                    <span class="returns">₹1,000 Bet - Returns ₹3,750</span>
                    <a href={null}>3.75</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else if props.bettingTipsType == "content"}
    <div class="block block-betting-tips-content">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <Heading {props} />
            {#if $project.key == 'bse'}
              <div class="tip">
                <span class="date">Idag</span>
                {#each dummyData[$project.key].tips as tip}
                  {#if (new Date(tip.startDate).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) || tip.box == 'today' }
                  {@const operator = $orgOperators.find(x => x.id === tip.operator)}
                    <div class="tip-content slim">
                      <ul>
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-role-supports-aria-props -->
                        <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                          <div class="tip-meta">
                            <span class="tip-time">{ tip.startTime }</span>
                            <span class="tip-sport">
                              <Icons name="dribbble" />
                            </span>
                          </div>
                          <div class="tip-outcome">
                            <div class="tip-bet">{ tip.outcome }</div>
                            <div class="tip-vs">{ tip.bet }</div>
                          </div>
                          <div class="tip-analysis" {hidden}>
                            <div class="header">
                              <span class="text">Analysis</span>
                              <a href={null}>Read full prediction</a>
                            </div>
                            <p>Manchester Originals have shown great form recently and are sure to bring their A-game. Ben Stokes is back playing as well, which should be a massive gain to their bowling game. We give the Originals a high chance of defeating the Superchargers.</p>
                          </div>
                          <div class={ "tip-place-bet _" + operator.name_lower }>
                            <span class="odds">{ tip.odds }</span>
                            <img alt={operator.name} class={ "_" + operator.name_lower} src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/{operator.name_lower}-logo">
                          </div>
                        </li>
                      </ul>
                    </div>
                  {/if}
                {/each}
              </div>

              <div class="tip">
                <span class="date">Imorgon</span>
                {#each dummyData[$project.key].tips as tip}
                {#if tip.box == 'tomorrow' }
                {@const operator = $orgOperators.find(x => x.id === tip.operator)}
                  <div class="tip-content slim">
                    <ul>
                      <!-- svelte-ignore a11y-click-events-have-key-events -->
                      <!-- svelte-ignore a11y-role-supports-aria-props -->
                      <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                        <div class="tip-meta">
                          <span class="tip-time">{ tip.startTime }</span>
                          <span class="tip-sport">
                            <Icons name="dribbble" />
                          </span>
                        </div>
                        <div class="tip-outcome">
                          <div class="tip-bet">{ tip.outcome }</div>
                          <div class="tip-vs">{ tip.bet }</div>
                        </div>
                        <div class="tip-analysis" {hidden}>
                          <div class="header">
                            <span class="text">Analysis</span>
                            <a href={null}>Read full prediction</a>
                          </div>
                          <p>Manchester Originals have shown great form recently and are sure to bring their A-game. Ben Stokes is back playing as well, which should be a massive gain to their bowling game. We give the Originals a high chance of defeating the Superchargers.</p>
                        </div>
                        <div class={ "tip-place-bet _" + operator.name_lower }>
                          <span class="odds">{ tip.odds }</span>
                          <img alt={operator.name} class={ "_" + operator.name_lower} src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/{operator.name_lower}-logo">
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/if}
                {/each}
              </div>
            {:else}
              <div class="tip bet-of-the-day">
                <h3>Bet of the Day</h3>
                <div class="tip-content">
                  <ul>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-role-supports-aria-props -->
                    <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                      <div class="tip-meta">
                        <span class="tip-time">19:30</span>
                        <span class="tip-teams">
                          <img alt="bangladesh" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/bangladesh">
                          <img alt="zimbabwe" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/zimbabwe">
                        </span>
                      </div>
                      <div class="tip-outcome">
                        <div class="tip-bet">Manchester Originals to win</div>
                        <div class="tip-vs">vs Northern Superchargers</div>
                      </div>
                      <div class="tip-analysis" {hidden}>
                        <div class="header">
                          <span class="text">Analysis</span>
                          <a href={null}>Read full prediction</a>
                        </div>
                        <p>Manchester Originals have shown great form recently and are sure to bring their A-game. Ben Stokes is back playing as well, which should be a massive gain to their bowling game. We give the Originals a high chance of defeating the Superchargers.</p>
                      </div>
                    </li>
                  </ul>
                  <div class="tip-place-bet">
                    <img alt="bet365" class="_bet365" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/bet365-icon">
                    <span class="tip-to-win">Returns ₹1,800</span>
                    <a href={null}>Place bet</a>
                  </div>
                </div>
              </div>
              <div class="tip jackpot-bet">
                <h3>Jackpot Bet</h3>
                <div class="tip-content">
                  <ul>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-role-supports-aria-props -->
                    <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                      <div class="tip-meta">
                        <span class="tip-time">19:30</span>
                        <span class="tip-teams">
                          <img alt="birmingham phoenix" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/birmingham-phoenix">
                          <img alt="southern brave" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/southern-brave">
                        </span>
                      </div>
                      <div class="tip-outcome">
                        <div class="tip-bet">Imran Tahir Best Bowler</div>
                        <div class="tip-vs">vs Southern Brave</div>
                      </div>
                      <div class="tip-analysis" {hidden}>
                        <div class="header">
                          <span class="text">Analysis</span>
                          <a href={null}>Read full prediction</a>
                        </div>
                        <p>Imran Tahir has been phenomenal so far in The Hundred 2022 edition. You get an odds boost on him being the best bowler for Birmingham Phoenix - yet again.</p>
                      </div>
                    </li>
                  </ul>
                  <div class="tip-place-bet">
                    <img alt="Dafabet" class="_dafabet" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/dafabet-icon">
                    <span class="tip-to-win">Returns ₹4,000</span>
                    <a href={null}>Place bet</a>
                  </div>
                </div>
              </div>
              <div class="tip bet-of-the-day">
                <h3>Dhruv's Triple Accumulator</h3>
                <div class="tip-content">
                  <ul>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-role-supports-aria-props -->
                    <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                      <div class="tip-meta">
                        <span class="tip-time">15:30</span>
                        <span class="tip-teams">
                          <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/india">
                          <img alt="england" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/england">
                        </span>
                      </div>
                      <div class="tip-outcome">
                        <div class="tip-bet">England to win</div>
                        <div class="tip-vs">vs India</div>
                      </div>
                      <div class="tip-analysis" {hidden}>
                        <div class="header">
                          <span class="text">Analysis</span>
                          <a href={null}>Read full prediction</a>
                        </div>
                        <p>India has been struggling against the pacey bowlers of England and without Kohli, Sharma and Dhawan available they are going to have a hard time keeping up with the runs.</p>
                      </div>
                    </li>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-role-supports-aria-props -->
                    <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                      <div class="tip-meta">
                        <span class="tip-time">18:00</span>
                        <span class="tip-teams">
                          <img alt="glamorgan" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/glamorgan">
                          <img alt="yorkshire" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/yorkshire">
                        </span>
                      </div>
                      <div class="tip-outcome">
                        <div class="tip-bet">Glamorgan to win</div>
                        <div class="tip-vs">vs Yorkshire</div>
                      </div>
                      <div class="tip-analysis" {hidden}>
                        <div class="header">
                          <span class="text">Analysis</span>
                          <a href={null}>Read full prediction</a>
                        </div>
                        <p>Given how the two sides have done up until now, Glamogan do appear ahead. They also have a better-balanced side between the two, at least on paper.</p>
                      </div>
                    </li>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-role-supports-aria-props -->
                    <li class="tip-match" aria-expanded={!hidden} on:click={toggle}>
                      <div class="tip-meta">
                        <span class="tip-time">19:30</span>
                        <span class="tip-teams">
                          <img alt="birmingham phoenix" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/birmingham-phoenix">
                          <img alt="southern brave" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/southern-brave">
                        </span>
                      </div>
                      <div class="tip-outcome">
                        <div class="tip-bet">Birmingham Phoenix to win</div>
                        <div class="tip-vs">vs Southern Brave</div>
                      </div>
                      <div class="tip-analysis" {hidden}>
                        <div class="header">
                          <span class="text">Analysis</span>
                          <a href={null}>Read full prediction</a>
                        </div>
                        <p>Birmingham Phoenix have a strong batting lineup that showed glimpses of what it was capable of in the previous match. The bowling may not be as strong as what Southern Brave has but the odds being offered to make this a risk worth taking.</p>
                      </div>
                    </li>
                  </ul>
                  <div class="tip-place-bet">
                    <img alt="betway" class="_betway" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/betway-icon">
                    <span class="tip-to-win">Returns ₹2,740</span>
                    <a href={null}>Place bet</a>
                  </div>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  {:else if props.bettingTipsType == "botd"}
    <!-- TODO: not sure if we need this one; could be merged with 'event' or 'content'?-->
    <div class="block block-botd">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="header">
              <h4>Cricket Bet of the Day</h4>
              <span>05 Aug</span>
            </div>
            <div class="row">
              <div class="botd-meta">
                <a href={null} class="event-tournament">The Hundred</a>
                <span class="botd-start">Fri 6th Aug 19:30</span>
              </div>
              <div class="botd-teams">
                <span class="team-a">
                  <img alt="manchester originals" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/manchester-originals">
                  <span>Manchester Origins</span>
                </span>
                <span class="team-b">
                  <img alt="northern superchargers" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/northern-superchargers">
                  <span>Northern Superchargers</span>
                </span>
              </div>
              <div class="botd-botd">
                <h6>Manchester Origins to win the match</h6>
                <p>The origins have had a few days extra rest, Player A is coming back from an injury and Superchargers are in horrible form.</p>
              </div>
              <div class="botd-odds">
                <div class="botd-operator">
                  <img alt="Betway" class="_betway" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/betway-icon">
                  <span class="returns">₹1,000 Bet - Returns ₹1,800</span>
                  <a href={null}>1.80</a>
                </div>
                <div class="botd-operator">
                  <img alt="Dafabet" class="_dafabet" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/dafabet-icon">
                  <span class="returns">₹1,000 Bet - Returns ₹1,750</span>
                  <a href={null}>1.75</a>
                </div>
              </div>
              <a class="more-odds" href={null}>More Odds</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p>Betting tips type not set</p>
  {/if}
{/if}

<style>
  .betting-tips-holder {
    background: #fff;
    border-radius: var(--bd-rad);
  }
  :global(.betting-tips-holder h2) {
    background: var(--clr-pri);
    font-size: 1rem;
    color: #eee;
    padding: 1rem;
    border-radius: var(--bd-rad) var(--bd-rad) 0 0;
    margin: 0;
  }
  .betting-tips-holder .tip {
    color: #222;
    font-size: 0.75rem;
    padding: 1rem;
    border-bottom: 1px solid #f6f6f6;
    display: grid;
  }
  .betting-tips-holder .tip.highlight {
    background: var(--clr-highlight);
  }
  .betting-tips-holder .tip .tip-outcome {
    font-weight: 600;
    font-size: 0.875rem;
    margin: 0 0 0.75rem 0;
  }
  .betting-tips-holder .tip .tip-analysis {
    display: grid;
    grid-template-areas:
      "rating reasoning"
      "analysis analysis"
      "bet bet";
  }
  .betting-tips-holder .tip .tip-rating {
    grid-area: rating;
    display: flex;
    align-items: center;
  }
  .betting-tips-holder .tip .tip-reasoning {
    grid-area: reasoning;
    color: orange;
    justify-self: flex-end;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    transition: all ease-in-out .2s;
    font-size: 0.65rem;
  }
  .betting-tips-holder .tip .tip-reasoning:after {
    content: "\203A";
    transform: rotate(90deg);
    display: inline-block;
    margin: 0 0 0 0.5rem;
  }
  .betting-tips-holder .tip .tip-reasoning:hover {
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  .betting-tips-holder .tip p {
    grid-area: analysis;
    padding: 0.5rem;
    background: #f6f6f6;
    border: 1px solid #eee;
    border-radius: 4px;
    margin: 0.75rem 0 0 0;
    line-height: 1.75;
    font-weight: 500;
    font-size: 0.75rem;
  }
  .betting-tips-holder .tip .place-bet {
    grid-area: bet;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2.5rem auto 5rem;
    align-items: center;
    margin: 1rem 0 0 0;
  }
  .betting-tips-holder .tip .place-bet img {
    width: 2rem;
    border-radius: 4px;
    padding: 0.15rem;
  }
  .betting-tips-holder .tip .place-bet .returns {
    color: #222;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .betting-tips-holder .tip .place-bet a {
    padding: 0.4rem 1rem;
    background: #ffdf1a;
    color: #222;
    border-radius: 4px;
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .betting-tips-holder .tip .place-bet a:hover {
    background: #e4e4e4;
  }

  /*
  CONTENT TIPS

  These are meant to be used in areas not attached to a specific 'match' or 'event'.
  So for example the home page, or maybe a tournament page. Places where the tips being fetched needs to be specified and needs a bit extra information so you know which match they are referring to.
  */

  .block-betting-tips-content .tip {
    border-radius: var(--bd-rad);
    margin: 0 0 1rem 0;
  }
  .block-betting-tips-content .tip h3,
  .block-betting-tips-content .tip > span {
    background: #232323;
    color: #fff;
    border-radius: var(--bd-rad-sm) var(--bd-rad-sm) 0 0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin: 0;
    width: 100%;
    display: flex;
  }
  .block-betting-tips-content .tip .tip-content {
    background: #fff;
    padding: 1rem;
    color: #222;
  }
  .block-betting-tips-content .tip .tip-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .block-betting-tips-content .tip .tip-content ul li {
    display: grid;
    grid-template-areas:
      "meta tip"
      "analysis analysis";
    grid-template-columns: calc(45px + 2rem) auto;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    margin: 0 0 1.5rem 0;
    padding: 0 0 1.5rem 0;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-meta {
    display: grid;
    border-right: 1px solid #eee;
    margin: 0 1rem 0 0;
    padding: 0 1rem 0 0;
    gap: 0.25rem;
    grid-area: meta;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-meta .tip-time {
    justify-self: center;
    align-self: flex-end;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-meta .tip-teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-meta .tip-teams img {
    width: 1.25rem;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-outcome {
    display: grid;
    grid-area: tip;
    grid-template-areas:
      "bet button"
      "vs button";
    grid-template-columns: auto 1.5rem;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-outcome:after {
    content: "\2038";
    grid-area: button;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-content: center;
    color: #999;
  }
  .block-betting-tips-content .tip .tip-content ul li[aria-expanded="true"] .tip-outcome:after {
    transform: rotate(0deg);
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-outcome .tip-bet {
    line-height: 1.25;
    font-weight: 600;
    grid-area: bet;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-outcome .tip-vs {
    font-size: 0.75rem;
    color: #999;
    grid-area: vs;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-analysis {
    grid-area: analysis;
    padding: 0.5rem;
    border-radius: 4px;
    background: #f2f2f2;
    font-size: 0.75rem;
    margin: 1rem 0 0 0;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-analysis .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-analysis .header a:hover{
    text-decoration: underline;
  }
  .block-betting-tips-content .tip .tip-content ul li .tip-analysis p {
    margin: 0;
  }

  .block-betting-tips-content .tip .tip-content .tip-place-bet {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 2.5rem 1fr auto;
  }
  .block-betting-tips-content .tip .tip-content .tip-place-bet img {
    width: 2rem;
    border-radius: 4px;
    padding: 0.25rem;
  }
  .block-betting-tips-content .tip .tip-content .tip-place-bet .tip-to-win {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .block-betting-tips-content .tip .tip-content .tip-place-bet a {
    padding: 0.4rem 1rem;
    background: #ffdf1a;
    color: #222;
    border-radius: 4px;
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .block-betting-tips-content .tip .tip-content .tip-place-bet a:hover {
    background: #e4e4e4;
  }


  .block-betting-tips-content .tip:last-of-type {
    margin: 0;
  }
  .block-betting-tips-content .tip .tip-content.slim {
    display: grid;
    grid-auto-flow: column;
    padding: 0.625rem;
    border-bottom: 1px solid var(--bg-clr-alt);
  }
  .block-betting-tips-content .tip .tip-content.slim:last-of-type {
    border-radius: 0 0 var(--bd-rad-sm) var(--bd-rad-sm);
  }
  .block-betting-tips-content .tip .tip-content.slim ul li {
    margin: 0;
    padding: 0;
    border: 0;
    grid-template-areas:
      "meta     tip      odds"
      "analysis analysis analysis";
    grid-template-columns: auto 1fr auto;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-meta {
    margin: 0 0.625rem 0 0;
    padding: 0 0.625rem 0 0;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-meta .tip-time {
    font-weight: 400;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-meta .tip-sport {
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-outcome {
    grid-template-areas:
      "vs"
      "bet";
    grid-template-columns: none;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-outcome:after {
    content: "";
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-outcome {
    grid-area: "odds";
  }

  .block-betting-tips-content .tip .tip-content.slim ul li .tip-place-bet {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: none;
    margin: 0 0 0 1rem;
    padding: 0.3125rem;
    border-radius: var(--bd-rad-sm);
    gap: 0.25rem;
    transition: opacity 0.2s ease-in-out;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-place-bet:hover {
    opacity: 0.9;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-place-bet span {
    font-size: 0.625rem;
    font-weight: 500;
    text-align: center;
    align-self: flex-end;
  }
  .block-betting-tips-content .tip .tip-content.slim ul li .tip-place-bet img {
    padding: 0;
    align-self: flex-start;
  }

  /* BLOCK BOTD */
  .block-botd {
    margin: 0 0 1rem 0;
  }

  .block-botd .header {
    background-color: #232323;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .block-botd .header h4 {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }
  .block-botd .header span {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #fff;
  }

  .block-botd .botd-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #bbb;
    font-size: 0.65rem;
    padding: 1rem 0 0.5rem 0;
    font-weight: 400;
    margin: 0 0 0.5rem 0;
  }
  .block-botd .botd-meta a {
    color: #bbb;
  }
  .block-botd .botd-meta a:hover {
    text-decoration: underline;
  }
  .block-botd .botd-teams {
    display: grid;
    color: #222;
    gap: 0.25rem;
  }
  .block-botd .botd-teams .team-a,
  .block-botd .botd-teams .team-b {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
  }
  .block-botd .botd-teams .team-a img,
  .block-botd .botd-teams .team-b img {
    width: 1.5rem;
    margin: 0 0.25rem 0 0;
    background: #131313;
    padding: 0.25rem;
    border-radius: 4px;
  }

  .block-botd .botd-botd {
    padding: 1.25rem 0.75rem;
    border-radius: 4px;
    font-size: .875rem;
    margin: 1rem 0;
    background-color: #e0e8f8;
    border: 1px solid #c0d2f5;
  }
  .block-botd .botd-botd h6 {
    margin: 0 0 0.5rem 0;
    color: #222;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 700;
  }
  .block-botd .botd-botd p {
    margin: 0;
    font-size: 0.65rem;
    font-weight: 500;
    line-height: 1.8;
  }
  .block-botd .botd-odds {
    display: grid;
    gap: 0.5rem;
  }
  .block-botd .botd-odds .botd-operator {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2.5rem auto 5rem;
    align-items: center;
  }
  .block-botd .botd-odds .botd-operator img {
    width: 2rem;
    border-radius: 4px;
    padding: 0.15rem;
  }
  .block-botd .returns {
    color: #222;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .block-botd .botd-odds .botd-operator a {
    padding: 0.4rem 1rem;
    background: #ffdf1a;
    color: #222;
    border-radius: 4px;
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .block-botd .botd-odds .botd-operator a:hover {
    background: #e4e4e4;
  }
  .block-botd .more-odds {
    margin: 1rem 0 0.25rem 0;
    padding: 1rem 0 0 0;
    font-size: 0.875rem;
    color: #ccc;
    font-weight: 600;
    text-align: center;
    border-top: 1px solid #ddd;
    display: inline-block;
    width: 100%;
  }
</style>