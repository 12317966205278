<!--
  TODO
  - create dark design
  - make countdown work
    * move to 'utils.js' (?)
  - show in admin view the settings for fetching matches
    * which settings?
  - use component for "brought to you by"

  TODO: (later)
  - replace the calculations with using 'prediction.data.status'
-->

<script>
  import supabase from "$lib/services/db";
  import { onMount } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Banner from "$lib/admin/components/blocks/testing/Banner.svelte";

  export let props = null;
  export let adminView = false;

  export let predictions;

  let currentDate = new Date();
  let historicDate = new Date('February 14, 2023');
  historicDate.setHours(historicDate.getHours() - 2);

  const load = async () => {
    const {data, error} = await supabase
      .from("sport_matches")
      .select(`
        id,
        home_team: team_a_id (name, code),
        away_team: team_b_id (name, code),
        season: season_id (name),
        data,
        starts_at
      `)
      .gt("starts_at", historicDate.toISOString())
      .order("starts_at", {asc: true})
      .limit(10)
    predictions = data
  }

  function getCountdown(match) {
    const now = currentDate.getTime();
    const startsAt = new Date(match.starts_at)
    const duration = startsAt - now;
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((duration % (1000 * 60)) / 1000);

    if(days > 0) {
      return `Starts in <span>${days}</span> days <span>${hours}</span> hours`
    }
    if(hours > 0) {
      return `Starts in <span>${hours}</span> hours <span>${minutes}</span> minutes`
    }
    if(minutes > 0) {
      return `Starts in <span>${minutes}</span> mins <span>${seconds}</span> seconds`
    }
    if(seconds > 0) {
      return `Starts in <span>${seconds}</span> seconds`
    }
    return `<span class="live">In-play</span>`
  }

  onMount( async() => {
    await load()
  })
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input label="Predictions List Type" type="select" bind:value={props.predictionsListType}>
      <option value="">Default</option>
      <option value="slider">Slider</option>
      <option value="list">List</option>
    </Input>
  </Group>
{:else}
  {#if props.predictionsListType == "slider"}
    <div class="block block-predictions block-slider">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="slider">
              {#if predictions}
                {#each predictions as prediction, i}
                  {@const eventLive = (currentDate - new Date(prediction.starts_at) > 0) ? true : false}
                  <div class="event">
                    <div class="event-meta-top">
                      <span class={"event-start " + (eventLive ? "live" : "upcoming")}>{ eventLive ? "Live" : "Upcoming" }</span>
                      <span class="event-type">{prediction.data.sub_title}</span>
                      <span class="event-location">{prediction.data.venue?.city}</span>
                    </div>
                    <div class="event-info">
                      <span class="team-a">
                        <img
                          alt="{prediction.home_team.name}"
                          src={`https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/d_varial:ocb:logos:teams:default-team.png/varial/ocb/logos/teams/${prediction.home_team.name.toLowerCase().replaceAll(' ', '-')}`}>
                        <span>{prediction.home_team.code}</span>
                      </span>
                      <span class="team-b">
                        <img
                        alt="{prediction.away_team.name}"
                        src={`https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/d_varial:ocb:logos:teams:default-team.png/varial/ocb/logos/teams/${prediction.away_team.name.toLowerCase().replaceAll(' ', '-')}`}>
                        <span>{prediction.away_team.code}</span>
                      </span>
                      <div class="event-score">
                        <span class="over">(37.6/50 ov)</span>
                        <span class="runs">203/1</span>
                      </div>
                      <span class="event-status">{@html getCountdown(prediction)}</span>
                    </div>
                    <div class="meta-end">
                      <a href="/predictions/sob-vs-los-12-aug-2022">Match prediction</a>
                    </div>
                  </div>
                {/each}
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else if props.predictionsListType == "list"}
    <div class="block block-predictions block-list">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <h2>Latest predictions</h2>
            <ul>
              {#if predictions}
                {#each predictions as prediction, i}
                  {@const match_start_date = new Date(prediction.starts_at)}
                  <li>
                    <a class="row" href="/predictions/sob-vs-los-12-aug-2022">
                      <span class="tournament">{prediction.season.name}</span>
                      <span class="team-a">
                        <img
                          alt={prediction.home_team.name}
                          src={`https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/d_varial:ocb:logos:teams:default-team.png/varial/ocb/logos/teams/${prediction.home_team.name.toLowerCase().replaceAll(' ', '-')}`}>
                        <span>{prediction.home_team.name}</span>
                      </span>
                      <span class="team-b">
                        <img
                          alt={prediction.away_team.name}
                          src={`https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/d_varial:ocb:logos:teams:default-team.png/varial/ocb/logos/teams/${prediction.away_team.name.toLowerCase().replaceAll(' ', '-')}`}>
                        <span>{prediction.away_team.name}</span>
                      </span>
                      <span class="datetime">
                        <span class="date">{ match_start_date.toLocaleDateString("en", { weekday: "short", day: "numeric", month: "short" }) }</span>
                        <span class="time">{ match_start_date.toLocaleTimeString("en", { hour12: false, hour: "2-digit", minute: "2-digit" }) }</span>
                      </span>
                    </a>
                  </li>
                {/each}
              {/if}
            </ul>

            <Banner props={ {bannerType: "brought-by", operator: "Fun88"} } />
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p>Predictions list type not chosen..</p>
  {/if}
{/if}

<style>
  .block-predictions .event {
    width: 250px;
    background: var(--bg-clr-alt);
    border-radius: var(--bd-rad);
    padding: 0.5rem;
    display: grid;
    border: 1px solid var(--bd-clr);
    transition: all 0.2s ease-in-out;
  }
  .block-predictions .event:hover {
    background: var(--bg-clr-alt-hvr);
  }
  .block-predictions .event .event-meta-top {
    font-size: 0.65rem;
    margin: 0 0 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .block-predictions .event .event-meta-top > *:after {
    content: "·";
    padding: 0 0.25rem;
  }
  .block-predictions .event .event-meta-top > *:last-of-type:after {
    content: "";
    padding: 0;
  }
  .block-predictions .event .event-meta-top .event-start {
    font-weight: 500;
  }
  .block-predictions .event .event-meta-top .event-start.live {
    color: red;
    font-weight: 600;
    text-transform: uppercase;
  }
  .block-predictions .event .event-meta-top .event-type {
    font-weight: 500;
  }

  .block-predictions .event .event-info {
    display: grid;
    align-items: center;
    grid-template-areas:
      "team-a score"
      "team-b ."
      "status status";
    font-size: 0.75rem;
  }
  .block-predictions .event .event-info .team-a {
    grid-area: team-a;
  }
  .block-predictions .event .event-info .team-b {
    grid-area: team-b;
  }
  .block-predictions .event .event-info .team-a,
  .block-predictions .event .event-info .team-b {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
  }
  .block-predictions .event .event-info .team-a img,
  .block-predictions .event .event-info .team-b img {
    width: 1rem;
    margin: 0 0.25rem 0 0;
    background: #131313;
    padding: 0.25rem;
    border-radius: var(--bd-rad);
  }
  .block-predictions .event .event-info .event-score {
    grid-area: score;
    text-align: flex-end;
  }
  .block-predictions .event .event-info .event-score .over {
    font-size: 0.6rem;
  }
  .block-predictions .event .event-info .event-score .runs {
    font-weight: bold;
    font-size: 0.9rem;
  }
  .block-predictions .event .event-info .event-status {
    grid-area: status;
    margin: 0.5rem 0 0.25rem 0;
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid var(--bd-clr);
    font-size: 0.6rem;
    font-weight: 300;
    color: #666;
    font-style: italic;
  }
  .meta-end {
    line-height: 1.25;
  }
  .meta-end a {
    margin: 0.125rem 0 0 0;
    font-size: 0.65rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }
  .meta-end a:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23222' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .block-predictions.list {
    grid-auto-flow: row;
  }
  /* .block-predictions.list .row .event {
    width: auto;
  } */

  /* PREDICTIONS LIST */

  .block-predictions.block-list .container {
    padding: 0;
  }
  .block-predictions.block-list h2 {
    background: #00f380;
    padding: 1rem;
    font-size: 1rem;
    margin: 0;
  }
  .block-predictions.block-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .block-predictions.block-list ul li {
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .block-predictions.block-list ul li:last-of-type {
    border: none;
  }
  .block-predictions.block-list ul li a {
    padding: 1.25rem 1rem;
    display: grid;
    gap: 0.25rem;
    font-weight: 600;
    font-size: 0.75rem;
    grid-template-areas:
      "tournament tournament"
      "team-a time"
      "team-b time";
    grid-template-columns: calc(100% - 100px - 0.25rem) 100px;
    transition: all ease-in-out .2s;
    text-decoration: none;
  }
  .block-predictions.block-list ul li a:hover {
    background: #eee;
  }
  .block-predictions.block-list ul li a .tournament {
    grid-area: tournament;
    width: 100%;
    font-size: 0.65rem;
    font-weight: 400;
    color: #666;
    margin: 0 0 0.25rem 0;
  }
  .block-predictions.block-list ul li .team-a {
    grid-area: team-a;
  }
  .block-predictions.block-list ul li .team-b {
    grid-area: team-b;
  }
  .block-predictions.block-list ul li .team-a,
  .block-predictions.block-list ul li .team-b {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1.75rem auto;
    align-items: center;
    line-height: 1.25;
  }
  .block-predictions.block-list ul li .team-a img,
  .block-predictions.block-list ul li .team-b img {
    width: 1.5rem;
    border-radius: 4px;
  }
  .block-predictions.block-list ul li .datetime {
    grid-area: time;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-left: 1px solid #ddd;
    font-size: 0.65rem;
    font-weight: 400;
    color: #666;
  }
  .block-predictions.block-list ul li .datetime .date {
    align-self: flex-end;
  }
  .block-predictions.block-list ul li .datetime .time {
    align-self: flex-start;
  }
</style>