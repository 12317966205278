<script>
  let hovering = null;
  export let index = null;
  export let post = null;
  export let open = null;
  export let draggable = false;
  let isActive = false; // True when it is dragged

  const drop = (event, target) => {
    event.dataTransfer.dropEffect = 'move'
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newContent = post.content
    const newOpen = open

    if (start < target) {
      newContent.splice(target + 1, 0, newContent[start]);
      newContent.splice(start, 1);
      newOpen.splice(target + 1, 0, newOpen[start]);
      newOpen.splice(start, 1);
    } else {
      newContent.splice(target, 0, newContent[start]);
      newContent.splice(start + 1, 1);
      newOpen.splice(target, 0, newOpen[start]);
      newOpen.splice(start + 1, 1);
    }
    post.content = newContent
    open = newOpen
    hovering = null
  }

  const dragstart = (event, i) => {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
    const start = i;
    event.dataTransfer.setData('text/plain', start);
    isActive = true;
  }

</script>

{#if draggable}
  <div
    draggable={true}
    on:dragstart={event => dragstart(event, index)}
    on:drop|preventDefault={event => drop(event, index)}
    ondragover="return false"
    on:dragenter={() => { hovering = index }}
    on:dragleave={() => { hovering = null }}
    on:dragend={() => isActive = false }
    class:is-dropzone={hovering === index}
    class:is-active={isActive}>
    <slot />
  </div>
{:else}
  <slot />
{/if}