<script>
  import Banner from "$lib/admin/components/blocks/testing/Banner.svelte";

  export let match = null;

  const startsAt = new Date(match?.starts_at)
  const sub_title = match?.data?.sub_title || {}
  const venue = match?.data?.venue || {}
  const status = match?.data?.status || {}
  const winner = match?.data?.winner || {}
  const messages = match?.data?.messages || {}
  const format = match?.data?.format || {}
  const play_status = match?.data?.play_status || {}
  const toss = match?.data?.toss || {}
  const play = match?.data?.play || {}
  const players = match?.data?.players || {}
  const notes = match?.data?.notes || {}
  const data_review = match?.data?.data_review || {}
  const squad = match?.data?.squad || {}
  const umpires = match?.data?.umpires || {}

  let matchInfo = "form";

  let countdown = null;
  updateCountdown()

  function updateCountdown() {
    const now = new Date().getTime();
    const duration = startsAt - now;
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((duration % (1000 * 60)) / 1000);
    if(days > 0) {
      countdown = `${days} days ${hours} hours`
      return
    }
    if(hours > 0) {
      countdown = `${hours} hours ${minutes} minutes`
      return
    }
    if(minutes > 0) {
      countdown = `${minutes} minutes ${seconds} seconds`
      return
    }
    countdown = `${seconds} seconds`
  }

  setInterval(updateCountdown, 1000)
</script>

<div class="block block-tip-match-meta">
  <div class="container">
    <div class="grid">
      <div class="g-col-12">
        <div class="tip-match-meta">
          <h1>
            <span>{match.home_team.code} vs {match.away_team.code}</span>
            <span>Match Prediction</span>
          </h1>
          <div class="home-team">
            <img alt="england" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/england">
            <div class="name">{match.home_team.code}</div>
            <div class="score">{play?.innings?.a_1?.score_str || "0/0 in 0.0"}</div>
          </div>
          <div class="away-team">
            <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_30/varial/ocb/logos/teams/india">
            <div class="name">{match.away_team.code}</div>
            <div class="score">{play?.innings?.b_1?.score_str || "0/0 in 0.0"}</div>
          </div>
          <div class="match-meta">
            <a href={null}>{match.sport_seasons.name}</a>
            <span class="time-start">{startsAt.getHours()}:{startsAt.getMinutes()}</span>
          </div>
          <div class="match-status">
            <p>Match starts in</p>
            <p>{countdown}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<Banner props={ {bannerType: "brought-by", operator: "Bet365"} } />

<div class="block block-tip-match-data">
  <div class="container">
    <div class="grid">
      <div class="g-col-12">
        <div class="tip-match-data">
          <ul class="tabs">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <li class="{matchInfo === 'stats' ? 'active' : ''}" on:click={() => matchInfo = "stats"}>Stats</li>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <li class="{matchInfo === 'form' ? 'active' : ''}" on:click={() => matchInfo = "form"}>Form</li>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <li class="{matchInfo === 'lineups' ? 'active' : ''}" on:click={() => matchInfo = "lineups"}>Lineups</li>
          </ul>
          <div class="content">
            {#if matchInfo == 'stats'}
              <div class="stats">
                <ul>
                  <li>test</li>
                </ul>
              </div>
            {:else if matchInfo == 'form'}
              <div class="form">
                <div class="home-team">
                  <h4>{match.home_team.name} Form</h4>
                  <ul>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">06 Aug 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">06 Aug 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">04 Aug 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                    <li class="draw">
                      <div class="logos">
                        <img alt="england" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/england">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">02 Aug 2022</span>
                        <span class="venue">Home</span>
                      </div>
                      <span class="vs">England</span>
                      <span class="result draw">D</span>
                    </li>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">21 Apr 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                  </ul>
                </div>
                <div class="away-team">
                  <h4>{match.away_team.name} Form</h4>
                  <ul>
                    <li class="win">
                      <div class="logos">
                        <img alt="new zealand" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/new-zealand">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">09 Aug 2022</span>
                        <span class="venue">Home</span>
                      </div>
                      <span class="vs">New Zealand</span>
                      <span class="result win">W</span>
                    </li>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">06 Aug 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">04 Aug 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                    <li class="draw">
                      <div class="logos">
                        <img alt="england" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/england">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">02 Aug 2022</span>
                        <span class="venue">Home</span>
                      </div>
                      <span class="vs">England</span>
                      <span class="result draw">D</span>
                    </li>
                    <li class="loss">
                      <div class="logos">
                        <img alt="india" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/teams/india">
                        <img alt="tours" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/logos/tournaments/tours">
                      </div>
                      <div class="form-meta">
                        <span class="date">21 Apr 2022</span>
                        <span class="venue">Away</span>
                      </div>
                      <span class="vs">India</span>
                      <span class="result loss">L</span>
                    </li>
                  </ul>
                </div>
              </div>
            {:else if matchInfo == 'lineups'}
              <div class="lineups">
                <div class="home-team">
                  <h4>{match.home_team.name} Playing 11</h4>
                  <ul>
                    {#if squad?.a?.playing_xi}
                      {#each squad.a.playing_xi as player_key}
                        <li>{players[player_key].player.name}</li>
                      {/each}
                    {:else}
                      <li>Squad data not available</li>
                    {/if}
                  </ul>
                </div>
                <div class="away-team">
                  <h4>{match.away_team.name} Playing 11</h4>
                  <ul>
                    {#if squad?.b?.playing_xi}
                      {#each squad.b.playing_xi as player_key}
                        <li>{players[player_key].player.name}</li>
                      {/each}
                    {:else}
                      <li>Squad data not available</li>
                    {/if}
                  </ul>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .block-tip-match-meta {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("https://res.cloudinary.com/qm-media/image/upload/f_auto,w_500/varial/ocb/assets/rainy-field");
    background-size: cover;
    color: #fff;
    margin: -1rem 0 0 0; /* TEMP: */
  }
  .block-tip-match-meta:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(30 31 51 / 70%);
  }
  .block-tip-match-meta .tip-match-meta {
    position: relative;
    display: grid;
    grid-template-areas:
      "header"
      "meta"
      "home"
      "away"
      "status";
    padding: 1.5rem 0;
  }

  .tip-match-meta h1 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    margin: 0 0 1rem 0;
    padding: 0 0 1rem 0;
    display: grid;
    grid-area: header;
  }
  .tip-match-meta h1 span:last-of-type {
    color: #f59c22;
  }
  .tip-match-meta .match-meta {
    grid-area: meta;
    margin: 0 0 0.25rem 0;
    font-size: 0.6rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ccc;
  }
  .tip-match-meta .match-meta a {
    color: #ccc;
  }

  .tip-match-meta .home-team {
    grid-area: home;
  }

  .tip-match-meta .away-team {
    grid-area: away;
  }
  .tip-match-meta .home-team,
  .tip-match-meta .away-team {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2rem 1fr auto;
    align-items: center;
  }
  .tip-match-meta .home-team img,
  .tip-match-meta .away-team img {
    width: 1.5rem;
    border-radius: 4px;
  }
  .tip-match-meta .home-team .name,
  .tip-match-meta .away-team .name {
    font-weight: 600;
  }
  .tip-match-meta .home-team .score,
  .tip-match-meta .away-team .score {
    font-weight: 500;
    font-size: 0.875rem;
  }

  .tip-match-meta .match-status {
    grid-area: status;
    margin: 1.5rem 0 0 0;
  }
  .tip-match-meta .match-status p {
    color: #ccc;
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 0.75rem;
  }
  .tip-match-meta .match-status p:first-of-type {
    text-transform: uppercase;
    color: #777;
  }

  .block-tip-match-data {
    background: #fff;
    margin: 0 0 1rem 0;
  }
  .block-tip-match-data .container {
    padding: 0;
  }
  .tip-match-data .tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    border-top: 1px solid #f6f6f6;
  }
  .tip-match-data .tabs li {
    padding: 0.5rem;
    background: #f6f6f6;
    border-right: 1px solid #ebecf5;
    font-size: 0.65rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
  .tip-match-data .tabs li.active {
    background: #14202c;
    color: #fff;
  }
  .tip-match-data .content {
    font-size: 0.75rem;
    padding: 1rem;
  }
  .tip-match-data .content .lineups {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
  .tip-match-data .content .lineups .home-team,
  .tip-match-data .content .lineups .away-team {
    padding: 0 0 0 1rem;
    text-align: right;
  }
  .tip-match-data .content .lineups .home-team {
    border-right: 1px solid #f6f6f6;
    padding: 0 1rem 0 0;
    text-align: left;
  }
  .tip-match-data .content .lineups h4 {
    font-size: 0.65rem;
    color: #222;
    margin: 0.25rem 0 0.75rem 0;
    font-weight: 600;
  }
  .tip-match-data .content .lineups ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .tip-match-data .content .lineups ul li {
    font-size: 0.65rem;
    padding: 0 0 0.25rem 0;
    font-weight: 500;
    color: #555;
  }

  .tip-match-data .content .form {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
  .tip-match-data .content .form .home-team {
    padding: 0 0.5rem 0 0;
    border-right: 1px solid #f6f6f6;
  }
  .tip-match-data .content .form .away-team {
    text-align: right;
    padding: 0 0 0 0.5rem;
  }
  .tip-match-data .content .form h4 {
    font-size: 0.65rem;
    color: #222;
    margin: 0.25rem 0 0.75rem 0;
    font-weight: 600;
  }
  .tip-match-data .content .form ul {
    margin: 0;
    padding: 0;
  }
  .tip-match-data .content .form ul li {
    display: grid;
    grid-template-columns: 1.5rem 0.25rem auto 1.5rem;
    grid-template-areas:
      "logos air form-meta result"
      "logos air vs result";
    margin: 0 0 0.75rem 0;
  }
  .tip-match-data .content .form .away-team ul li {
    grid-template-areas:
      "result form-meta air logos"
      "result vs air logos";
    grid-template-columns: 1.5rem auto 0.25rem 1.5rem;
  }
  .tip-match-data .content .form ul li .logos {
    grid-area: logos;
    position: relative;
  }
  .tip-match-data .content .form ul li .logos img {
    display: block;
    width: 1.25rem;
  }
  .tip-match-data .content .form ul li .logos img:last-of-type {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0.8rem;
  }
  .tip-match-data .content .form ul li .form-meta {
    grid-area: form-meta;
    font-size: 0.45rem;
    font-weight: 500;
    color: #666;
  }
  .tip-match-data .content .form ul li .vs {
    grid-area: vs;
    font-size: 0.65rem;
    font-weight: 500;
    color: #333;
    line-height: 1.25;
  }
  .tip-match-data .content .form ul li .result {
    grid-area: result;
    background: #ddd;
    display: grid;
    place-content: center;
    font-weight: 600;
    border-radius: 4px;
  }
  .tip-match-data .content .form ul li .result.win {
    background: #5cea5c;
    color: green;
  }
  .tip-match-data .content .form ul li .result.loss {
    background: #ffa2a2;
    color: red;
  }
</style>