<script>
  import { project, lotteryResults, translations } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Terms from '$lib/admin/components/blocks/helpers/Terms.svelte';
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";
  import { numberSpacing } from '$lib/services/utils'

  export let props = null;
  export let adminView = false;

  let lotteryData = null

  if (props.gameType === 'lottery') {
    lotteryData = $lotteryResults?.find(obj => obj.lottery_id === Number(props?.lottery))
  }

  const prize_names = {
    "3": {
      "rank1": "5 + 2 rätt",
      "rank2": "5 + 1 rätt",
      "rank3": "5 rätt",
      "rank4": "4 + 2 rätt",
      "rank5": "4 + 1 rätt",
      "rank6": "3 + 2 rätt",
      "rank7": "4 rätt",
      "rank8": "2 + 2 rätt",
      "rank9": "3 + 1 rätt",
      "rank10": "3 rätt",
      "rank11": "1 + 2 rätt",
      "rank12": "2 + 1 rätt",
      "rank13": "2 rätt"
    },
    "2": {
      "rank1": "5 + 1 rätt",
      "rank2": "5 rätt",
      "rank3": "4 + 1 rätt",
      "rank4": "4 rätt",
      "rank5": "3 + 1 rätt",
      "rank6": "3 rätt",
      "rank7": "2 + 1 rätt",
      "rank8": "1 + 1 rätt",
      "rank9": "0 + 1 rätt"
    },
    "1": {
      "rank1": "5 + 1 rätt",
      "rank2": "5 rätt",
      "rank3": "4 + 1 rätt",
      "rank4": "4 rätt",
      "rank5": "3 + 1 rätt",
      "rank6": "3 rätt",
      "rank7": "2 + 1 rätt",
      "rank8": "1 + 1 rätt",
      "rank9": "0 + 1 rätt"
    },
    "5": {
      "rank1": "5 + 1 rätt",
      "rank2": "5 rätt",
      "rank3": "4 + 1 rätt",
      "rank4": "4 rätt",
      "rank5": "3 + 1 rätt",
      "rank6": "3 rätt",
      "rank7": "2 + 1 rätt",
      "rank8": "1 + 1 rätt",
      "rank9": "0 + 1 rätt",
      "rank0": "0 + 0 rätt"
    }
  }

  let ctaData = {
    "Lotto Onsdag": {
      "operator": "/spela/svenska-spel-tur",
      "text": "Lotto"
    },
    "Lotto Lördag": {
      "operator": "/spela/svenska-spel-tur",
      "text": "Lotto"
    },
    "EuroJackpot": {
      "operator": "/spela/svenska-spel-tur",
      "text": "EuroJackpot"
    },
    "Vikinglotto": {
      "operator": "/spela/svenska-spel-tur",
      "text": "Vikinglotto"
    },
    "EuroMillions": {
      "operator": "/spela/thelotter",
      "text": "EuroMillions"
    },
    "MegaMillions": {
      "operator": "/spela/thelotter",
      "text": "MegaMillions"
    },
    "Powerball": {
      "operator": "/spela/thelotter",
      "text": "PowerBall"
    },
    "Cash4Life": {
      "operator": "/spela/thelotter",
      "text": "Cash4Life"
    }
  }

  let svenskaSpelSportsData = {
    "1": {
      "name": "Stryktipset",
      "name_lower": "stryktipset",
      "cta": "Till Stryktipset",
      "date": "2023-08-12 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "event": "Manchester City - Newcastle",
          "score": "1 - 0",
          "result": "1"
        },
        {
          "event": "Tottenham - Manchester United",
          "score": "2 - 0",
          "result": "1"
        },
        {
          "event": "Liverpool - Bournemouth",
          "score": "3 - 1",
          "result": "1"
        },
        {
          "event": "Fulham - Brentford",
          "score": "0 - 3",
          "result": "2"
        },
        {
          "event": "Wolverhamption - Brighton",
          "score": "1 - 4",
          "result": "2"
        },
        {
          "event": "Blackburn Rovers - Hull",
          "score": "1 - 2",
          "result": "2"
        },
        {
          "event": "Bristol City - Birmingham",
          "score": "0 - 2",
          "result": "1"
        },
        {
          "event": "Middlesbrough - Huddersfield",
          "score": "1 - 1",
          "result": "X"
        },
        {
          "event": "Sheffield W - Preston",
          "score": "0 - 1",
          "result": "2"
        },
        {
          "event": "Stoke - Watford",
          "score": "1 - 0",
          "result": "1"
        },
        {
          "event": "Sunderland - Rotherham",
          "score": "2 - 1",
          "result": "1"
        },
        {
          "event": "Swansea - Coventry",
          "score": "1 - 1",
          "result": "X"
        },
        {
          "event": "Queens Park Rangers - Ipswich",
          "score": "0 - 1",
          "result": "2"
        }
      ],
      "winners": [
        {
          "result": "13 rätt",
          "winners": 177,
          "prize": 36839
        },
        {
          "result": "12 rätt",
          "winners": 4033,
          "prize": 606
        },
        {
          "result": "11 rätt",
          "winners": 38586,
          "prize": 50
        },
        {
          "result": "10 rätt",
          "winners": 222283,
          "prize": 18
        }
      ],
      "turnover": 25078965
    },
    "2": {
      "name": "Stryktipset Upcoming",
      "name_lower": "stryktipset",
      "cta": "Lämna in rad",
      "date": "2023-08-19 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "upcoming": [
        {
          "event": "Brighton - West Ham",
          "odds": {
            "1": {
              "odds": "1,49",
              "folket": "58%"
            },
            "X": {
              "odds": "4,60",
              "folket": "21%"
            },
            "2": {
              "odds": "5,95",
              "folket": "21%"
            }
          }
        },
        {
          "event": "Arsenal - Fulham",
          "odds": {
            "1": {
              "odds": "1,24",
              "folket": "57%"
            },
            "X": {
              "odds": "6,05",
              "folket": "22%"
            },
            "2": {
              "odds": "11,40",
              "folket": "21%"
            }
          }
        }
      ],
      "turnover": 666595
    },
    "3": {
      "name": "Topptipset",
      "name_lower": "topptipset",
      "cta": "Till Topptipset",
      "date": "2023-08-27 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "event": "New York Red Bulls - Inter Miami",
          "score": "0 - 2"
        },
        {
          "event": "Atlanta United FC - Nashville",
          "score": "4 - 0"
        },
        {
          "event": "Charlotte FC - Los Angeles FC",
          "score": "2 - 1"
        },
        {
          "event": "CF Montreal - New England Revolution",
          "score": "1 - 0"
        },
        {
          "event": "Sporting Kansas City - San Jose Earthquakes",
          "score": "3 - 0"
        },
        {
          "event": "FC Dallas - Austin FC",
          "score": "1 - 0"
        },
        {
          "event": "LA Galaxy - Chicago Fire",
          "score": "3 - 0"
        },
        {
          "event": "Portland Timbers - Vancouver Whitecaps",
          "score": "2 - 3"
        }
      ],
      "winners": [
        {
          "result": "8 rätt",
          "winners": 235,
          "prize": 619
        }
      ],
      "turnover": 207819
    },
    "5": {
      "name": "Måltipset",
      "name_lower": "maltipset",
      "cta": "Till Måltipset",
      "date": "2023-08-26 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "event": "Brighton - West Ham",
          "score": "1 - 3",
          "row": "1"
        },
        {
          "event": "Arsenal - Fulham",
          "score": "2 - 2",
          "row": "2"
        },
        {
          "event": "Manchester United - Nottingham Forest",
          "score": "3 - 2",
          "row": "3"
        },
        {
          "event": "Ipswich - Leeds",
          "score": "3 - 4",
          "row": "6"
        },
        {
          "event": "Huddersfield - Norwich",
          "score": "0 - 4",
          "row": "10"
        },
        {
          "event": "West Bromwich - Middlesbrough",
          "score": "4 - 2",
          "row": "13"
        },
        {
          "event": "Rotherham - Leicester",
          "score": "1 - 2",
          "row": "14"
        },
        {
          "event": "Peterborough - Derby",
          "score": "2 - 4",
          "row": "24"
        }
      ],
      "winners": [
        {
          "result": "8 rätt",
          "winners": 0,
          "prize": 0
        },
        {
          "result": "7 rätt",
          "winners": 52,
          "prize": 990
        },
        {
          "result": "6 rätt",
          "winners": 1151,
          "prize": 55
        },
        {
          "result": "5 rätt (4 frispelsrader)",
          "winners": 8983,
          "prize": 10
        }
      ],
      "turnover": 755275
    },
    "6": {
      "name": "Europatipset",
      "name_lower": "europatipset",
      "cta": "Till Europatipset",
      "date": "2023-08-24 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "event": "BK Häcken - Aberdeen",
          "score": "2 - 2"
        },
        {
          "event": "Ludogorets - Ajax",
          "score": "1 - 4"
        },
        {
          "event": "Royale Union SG - Lugano",
          "score": "2 - 0"
        },
        {
          "event": "Dinamo Zagreb - Sparta Prag",
          "score": "3 - 1"
        },
        {
          "event": "Olimpija Ljubljana - Qarabag",
          "score": "0 - 2"
        },
        {
          "event": "Slovan Bratislava - Aris Limassol FC",
          "score": "2 - 1"
        },
        {
          "event": "KI Klaksvik - FC Sheriff Tiraspol",
          "score": "1 - 1"
        },
        {
          "event": "Rapid Wien - Fiorentina",
          "score": "1 - 0"
        },
        {
          "event": "Osasuna - Club Brügge",
          "score": "1 - 2"
        },
        {
          "event": "Dynamo Kiev - Besiktas",
          "score": "2 - 3"
        },
        {
          "event": "Fenerbahçe - Twente",
          "score": "5 - 1"
        },
        {
          "event": "FC Midtjylland - Legia Warszawa",
          "score": "3 - 3"
        },
        {
          "event": "Sepsi OSK - Bodö/Glimt",
          "score": "2 - 2"
        }
      ],
      "winners": [
        {
          "result": "13 rätt",
          "winners": 1,
          "prize": 1195590
        },
        {
          "result": "12 rätt",
          "winners": 104,
          "prize": 6484
        },
        {
          "result": "11 rätt",
          "winners": 1838,
          "prize": 200
        },
        {
          "result": "10 rätt",
          "winners": 16084,
          "prize": 47
        }
      ],
      "turnover": 4716335
    },
    "7": {
      "name": "Europatipset Upcoming",
      "name_lower": "europatipset",
      "cta": "Lämna in rad",
      "date": "2023-08-27 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "upcoming": [
        {
          "event": "Newcastle - Liverpool",
          "odds": {
            "1": {
              "odds": "2,18",
              "folket": "45%"
            },
            "X": {
              "odds": "3,89",
              "folket": "24%"
            },
            "2": {
              "odds": "3,13",
              "folket": "31%"
            }
          }
        },
        {
          "event": "Burnley - Aston Villa",
          "odds": {
            "1": {
              "odds": "3,29",
              "folket": "21%"
            },
            "X": {
              "odds": "3,66",
              "folket": "25%"
            },
            "2": {
              "odds": "2,18",
              "folket": "54%"
            }
          }
        }
      ],
      "turnover": 9398369
    },
    "8": {
      "name": "Oddset Powerplay",
      "name_lower": "oddset",
      "cta": "Till Oddset Powerplay",
      "date": "2023-08-25 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "event": "Rögle BK - Växjö Lakers",
          "score": "4 - 1"
        },
        {
          "event": "Färjestads BK - Örebro HK",
          "score": "1 - 4"
        },
        {
          "event": "HC Ambri - Piotta - Luleå HF",
          "score": "3 - 1"
        },
        {
          "event": "Skellefteå AIK - IF Björklöven",
          "score": "3 - 3"
        },
        {
          "event": "Tingsryds AIF - Nybro Vikings IF",
          "score": "1 - 5"
        },
        {
          "event": "Visby - Roma HK - Djurgårdens IF",
          "score": "0 - 6"
        },
        {
          "event": "Kalmar HC - Tranås AIF",
          "score": "5 - 0"
        },
        {
          "event": "Västerviks IK - Vimmerby HC",
          "score": "3 - 2"
        }
      ],
      "winners": [
        {
          "result": "8 rätt",
          "winners": 204,
          "prize": 1181
        }
      ],
      "turnover": 344098
    },
    "9": {
      "name": "Oddset Challenge",
      "name_lower": "oddset",
      "cta": "Till Oddset Challenge",
      "date": "2023-08-27 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "event": "Real Salt Lake - Houston Dynamo",
          "score": "0 - 3",
          "result": "2"
        },
        {
          "event": "Real Salt Lake - Houston Dynamo",
          "score": "0 - 3",
          "result": "2-3"
        },
        {
          "event": "LA Galaxy - Chicago Fire",
          "score": "3 - 0",
          "result": "1"
        },
        {
          "event": "LA Galaxy - Chicago Fire",
          "score": "3 - 0",
          "result": "2-3"
        },
        {
          "event": "Portland Timbers - Vancouver Whitecaps",
          "score": "2-3",
          "result": "2"
        },
        {
          "event": "Portland Timbers - Vancouver Whitecaps",
          "score": "2-3",
          "result": "4+"
        }
      ],
      "winners": [
        {
          "result": "Alla rätt",
          "winners": 15,
          "prize": 111
        }
      ],
      "turnover": 11130
    },
    "10": {
      "name": "Oddset Bomben",
      "name_lower": "oddset",
      "cta": "Till Oddset Bomben",
      "date": "2023-08-26 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "results": [
            {
              "event": "Bournemouth - Tottenham",
              "score": "0 - 2"
            },
            {
              "event": "Örebro - Västerås",
              "score": "1 - 1"
            },
            {
              "event": "Brommapojkarna - Linköpings FC",
              "score": "2 - 5"
            }
          ],
          "turnover": 56170,
          "odds": 11234.00
        },
        {
          "results": [
            {
              "event": "Frölunda HC - Djurgårdens IF",
              "score": "2 - 2"
            },
            {
              "event": "Leksands IF - Mora IK",
              "score": "4 - 2"
            },
            {
              "event": "Herlev Eagles - Södertälje SK",
              "score": "0 - 4"
            }
          ],
          "turnover": 33644,
          "odds": 20186.40
        }
      ]
    },
    "11": {
      "name": "Oddset Matchen",
      "name_lower": "oddset",
      "cta": "Till Oddset Matchen",
      "date": "2023-08-26 12:00:00",
      "operator": {
        "name": "Svenska Spel Sport & Casino",
        "url": "/spela/svenska-spel-sport-casino/sports"
      },
      "results": [
        {
          "results": [
            {
              "event": "Örebro - Västerås",
              "score": "1 - 1"
            }
          ],
          "turnover": 11977,
          "odds": 8.20
        },
        {
          "results": [
            {
              "event": "Brommapojkarna - Linköpings FC",
              "score": "2 - 5"
            }
          ],
          "turnover": 2891,
          "odds": 67.46
        },
        {
          "results": [
            {
              "event": "Bournemouth - Tottenham",
              "score": "0 - 2"
            }
          ],
          "turnover": 14520,
          "odds": 7.65
        }
      ]
    }
  }
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      bind:value={props.gameType}
      label="Game type"
      type="select">
      <option value="lottery">Lottery</option>
      <option value="svenska-spel-sports">Svenska Spel Sports</option>
    </Input>
    {#if props.gameType === 'lottery'}
      <Input
        bind:value={props.game}
        label="Lottery"
        type="select"
        on:change>
        <option value="">not selected</option>
        <option value="7">Lotto Onsdag</option>
        <option value="6">Lotto Lördag</option>
        <option value="4">EuroJackpot</option>
        <option value="8">Vikinglotto</option>
        <option value="5">Cash4Life</option>
        <option value="3">EuroMillions</option>
        <option value="2">MegaMillions</option>
        <option value="1">Powerball</option>
      </Input>
    {:else}
      <Input
        bind:value={props.game}
        label="Svenska Spel Sports"
        type="select"
        on:change>
        <option value="">not selected</option>
        <option value="1">Stryktipset</option>
        <option value="2">Stryktipset Upcoming</option>
        <option value="3">Topptipset</option>
        <option value="5">Måltipset</option>
        <option value="6">Europatipset</option>
        <option value="7">Europatipset Upcoming</option>
        <option value="8">Oddset Powerplay</option>
        <option value="9">Oddset Challenge</option>
        <option value="10">Oddset Bomben</option>
        <option value="11">Oddset Matchen</option>
      </Input>
    {/if}
    <Input
      bind:value={props.gameDescription}
      label="Lottery description"
      type="textarea"
      on:change />
  </Group>
{:else}
  {#if lotteryData && props.gameType === "lottery"}
    <Block
      blockClass={`block block-focus-panel block-focus-panel-box block-lottery-results _${lotteryData.lottery_name.toLowerCase().replaceAll(' ', '-').replaceAll('ö', 'o')}` }
      gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
      {props}>
      <Heading {props} />

      <div class="main-event-results">
        <div class="box-header">
          <img
            loading="lazy"
            alt={`${lotteryData.lottery_name}`}
            src={`${$project.data.imageFolders.logos}/${lotteryData.lottery_name.toLowerCase().replaceAll(' ', '-').replaceAll('ö', 'o')}-logo-badge.png?auto=format`}>
          <h4>{lotteryData.lottery_name} <Translate text="Result" /> -
            {new Date(lotteryData.draw_date).toLocaleDateString(`${$project.settings.lang}-${$project.settings.market.toUpperCase()}`, {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric"
              })}</h4>
          {#if props.lotteryDescription}
            <p>{props.lotteryDescription}</p>
          {/if}
        </div>

        <div class="box-main">
          {#if lotteryData.lottery_name === 'Lotto Onsdag' || lotteryData.lottery_name === 'Lotto Lördag'}
            <div class="lottery-results lotto-1">
              <h4>Lotto 1</h4>
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes).find(obj => obj.draw === 'Lotto1')?.items as prize, i}
                    <tr>
                      <td>{prize.name}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
            <div class="lottery-results lotto-2">
              <h4>Lotto 2</h4>
              <div class="numbers-draw">
                {#each lotteryData.draw_two_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_two_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes).find(obj => obj.draw === 'Lotto2')?.items as prize, i}
                    <tr>
                      <td>{prize.name}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>

            {#if lotteryData?.joker}
              <div class="lottery-results joker">
                <h4>Joker</h4>
                <div class="numbers-draw">
                  {#each lotteryData.joker as number, i}
                    <div class={`number number-${i}`}>{number}</div>
                  {/each}
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                    </tr>
                  </thead>
                  <tbody>
                    {#each JSON.parse(lotteryData.joker_prizes)[0].items as prize, i}
                      <tr>
                        <td>{prize.name}</td>
                        <td>{numberSpacing(prize.winners)} st</td>
                        <td>{numberSpacing(prize.amount)} kr</td>
                      </tr>
                    {/each}
                  </tbody>
                </table>
              </div>
            {/if}
          {/if}
          {#if lotteryData.lottery_name === 'EuroJackpot'}
            <p><b>Jackpot:</b> c:a {lotteryData.jackpot} miljoner kronar</p>
            <div class="lottery-results eurojackpot">
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>

                  {#each JSON.parse(lotteryData.prizes)[0].items.sort((a, b) => a.amount < b.amount) as prize, i}
                    <tr>
                      <td>{prize.name} rätt</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          {/if}
          {#if lotteryData.lottery_name.toLowerCase() === 'vikinglotto'}
            <div class="lottery-results vikinglotto">
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes)[0].items as prize, i}
                    <tr>
                      <td>{prize.name}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>

            {#if lotteryData?.joker}
              <div class="lottery-results joker">
                <h4>Joker</h4>
                <div class="numbers-draw">
                  {#each lotteryData.joker as number, i}
                    <div class={`number number-${i}`}>{number}</div>
                  {/each}
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                    </tr>
                  </thead>
                  <tbody>
                    {#each JSON.parse(lotteryData.joker_prizes)[0].items as prize, i}
                      <tr>
                        <td>{prize.name}</td>
                        <td>{numberSpacing(prize.winners)} st</td>
                        <td>{numberSpacing(prize.amount)} kr</td>
                      </tr>
                    {/each}
                  </tbody>
                </table>
              </div>
            {/if}
          {/if}
          {#if lotteryData.lottery_name === 'EuroMillions'}
            <div class="lottery-results euromillions">
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes)[0].items.sort((a, b) => a.amount < b.amount) as prize, i}
                    <tr>
                      <td>{prize_names[props.game][prize.name]}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          {/if}
          {#if lotteryData.lottery_name === 'MegaMillions'}
            <p><b>Jackpot:</b> c:a {lotteryData.jackpot} miljoner kronar</p>
            <div class="lottery-results megamillions">
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
                {#if lotteryData.multiplier}
                  <div class={`number multiplier`}>x{lotteryData.multiplier}</div>
                {/if}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes)[0].items.sort((a, b) => a.amount < b.amount) as prize, i}
                    <tr>
                      <td>{prize_names[props.game][prize.name]}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          {/if}
          {#if lotteryData.lottery_name === 'Powerball'}
            <p><b>Jackpot:</b> c:a {lotteryData.jackpot} miljoner kronar</p>
            <div class="lottery-results megamillions">
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
                {#if lotteryData.multiplier}
                  <div class={`number multiplier`}>x{lotteryData.multiplier}</div>
                {/if}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes)[0].items.sort((a, b) => a.amount < b.amount) as prize, i}
                    <tr>
                      <td>{prize_names[props.game][prize.name]}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          {/if}
          {#if lotteryData.lottery_name === 'Cash4Life'}
            <p><b>Jackpot:</b> 10 000 kr per dag livet ut!</p>
            <div class="lottery-results megamillions">
              <div class="numbers-draw">
                {#each lotteryData.draw_one_numbers as number, i}
                  <div class={`number number-${i}`}>{number}</div>
                {/each}
                {#each lotteryData.draw_one_extra_numbers as number, i}
                  <div class={`number extra number-${i}`}>{number}</div>
                {/each}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Vinstnivå</th><th>Antal vinnare</th><th>Vinstsumma</th>
                  </tr>
                </thead>
                <tbody>
                  {#each JSON.parse(lotteryData.prizes)[0].items.sort((a, b) => a.amount < b.amount) as prize, i}
                    <tr>
                      <td>{prize_names[props.game][prize.name]}</td>
                      <td>{numberSpacing(prize.winners)} st</td>
                      <td>{numberSpacing(prize.amount)} kr</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          {/if}
        </div>

        <div class="box-footer">
          <div class="next-draw">
            <Icons name="clock" width="36px" height="36px" strokeWidth="2" />
            <b><Translate text="Next draw" /> {new Date(lotteryData.next_draw_date).toLocaleString(`${$project.settings.lang}-${$project.settings.market.toUpperCase()}`, {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
              })}</b>
          </div>
          <a href={ctaData[lotteryData.lottery_name]?.operator} class="cta">
            <span>Till {ctaData[lotteryData.lottery_name]?.text}</span>
          </a>
          <i><Terms /></i>
        </div>
      </div>

      {#if props.linkAnchor && props.linkUrl}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
              <a class="more" href={props.linkUrl}>{props.linkAnchor}</a>
          </div>
        </div>
      {/if}
    </Block>
  {:else if svenskaSpelSportsData && props.gameType === "svenska-spel-sports"}
    {@const game = svenskaSpelSportsData[props.game]}
    <Block
      blockClass={`block block-focus-panel block-focus-panel-box block-svenska-spel-sports _${game.name_lower}` }
      gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
      {props}>
      <Heading {props} />

      <div class="main-event-results">
        <div class="box-header">
          <img loading="lazy" alt={`${game.name}`} src={`${$project.data.imageFolders.logos}/${game.name_lower}-logo-badge.png?auto=format`}>
          <h4>{game.name} <Translate text="Result" /> -
            {new Date(game.date).toLocaleDateString(`${$project.settings.lang}-${$project.settings.market.toUpperCase()}`, {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric"
              })}</h4>
          {#if props.gameDescription}
            <p>{props.gameDescription}</p>
          {/if}
        </div>

        <div class="box-main">
          {#if game.name === 'Stryktipset'}
            <div class="stryktipset-results">
              <table class="results">
                <thead>
                  <tr>
                    <th colspan="4">Rätt rad</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.results as event, i}
                    <tr>
                      <td class="order">{i + 1}.</td>
                      <td class="event">{event.event}</td>
                      <td class="score"><span>{event.score}</span></td>
                      <td class="result">{event.result}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
              <table class="winners">
                <thead>
                  <tr>
                    <th>Antal rätt</th><th>Antal vinnare</th><th>Utdelning</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.winners as event, i}
                    <tr>
                      <td>{event.result}</td>
                      <td>{numberSpacing(event.winners)} st</td>
                      <td>{numberSpacing(event.prize)} kr</td>
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="3">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
              </table>
            </div>
          {:else if game.name === 'Stryktipset Upcoming'}
            <div class="stryktipset-results upcoming">
              <table class="upcoming">
                <thead>
                  <tr><th colspan="5">Helgens matcher</th></tr>
                </thead>
                <tbody>
                  {#each game.upcoming as event, i}
                    <tr>
                      <td>{i + 1}.</td>
                      <td class="event">
                        <span>{event.event}</span>
                        <span>Odds</span>
                        <span>Svenska folket</span>
                        <span>Tio tidningar</span>
                      </td>
                      {#each ["1", "X", "2"] as outcome, i}
                        <td>
                          <span>{outcome}</span>
                          <span>{event.odds[outcome].odds}</span>
                          <span>{event.odds[outcome].folket}</span>
                        </td>
                      {/each}
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="5">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
                </tbody>
              </table>
            </div>
          {:else if game.name === 'Topptipset'}
            <div class="topptipset-results">
              <table class="results">
                <thead>
                  <tr>
                    <th colspan="4">Rätt rad</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.results as event, i}
                  {@const game_result = Number(event.score.split(" - ")[0]) > Number(event.score.split(" - ")[1]) ? "1" : Number(event.score.split(" - ")[0]) < Number(event.score.split(" - ")[1]) ? "2" : "X"}
                    <tr>
                      <td class="order">{i + 1}.</td>
                      <td class="event">{event.event}</td>
                      <td class="score"><span>{event.score}</span></td>
                      <td class="result">{game_result}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
              <table class="winners">
                <thead>
                  <tr>
                    <th>Antal rätt</th><th>Antal vinnare</th><th>Utdelning</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.winners as event, i}
                    <tr>
                      <td>{event.result}</td>
                      <td>{numberSpacing(event.winners)} st</td>
                      <td>{numberSpacing(event.prize)} kr</td>
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="3">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
              </table>
            </div>
          {:else if game.name === 'Måltipset'}
            <div class="maltipset-results">
              <table class="results">
                <thead>
                  <tr>
                    <th colspan="4">Rätt rad</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.results as event, i}
                    <tr>
                      <td class="order">{i + 1}.</td>
                      <td class="event">{event.event}</td>
                      <td class="score"><span>{event.score}</span></td>
                      <td class="result">{event.row}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
              <table class="winners">
                <thead>
                  <tr>
                    <th>Antal rätt</th><th>Antal vinnare</th><th>Utdelning</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.winners as event, i}
                    <tr>
                      <td>{event.result}</td>
                      <td>{numberSpacing(event.winners)} st</td>
                      <td>{numberSpacing(event.prize)} kr</td>
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="3">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
              </table>
            </div>
          {:else if game.name === 'Europatipset'}
            <div class="europatipset-results">
              <table class="results">
                <thead>
                  <tr>
                    <th colspan="4">Rätt rad</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.results as event, i}
                  {@const game_result = Number(event.score.split(" - ")[0]) > Number(event.score.split(" - ")[1]) ? "1" : Number(event.score.split(" - ")[0]) < Number(event.score.split(" - ")[1]) ? "2" : "X"}
                    <tr>
                      <td class="order">{i + 1}.</td>
                      <td class="event">{event.event}</td>
                      <td class="score"><span>{event.score}</span></td>
                      <td class="result">{game_result}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
              <table class="winners">
                <thead>
                  <tr>
                    <th>Antal rätt</th><th>Antal vinnare</th><th>Utdelning</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.winners as event, i}
                    <tr>
                      <td>{event.result}</td>
                      <td>{numberSpacing(event.winners)} st</td>
                      <td>{numberSpacing(event.prize)} kr</td>
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="3">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
              </table>
            </div>
          {:else if game.name === 'Europatipset Upcoming'}
            <div class="europatipset-results upcoming">
              <table class="upcoming">
                <thead>
                  <tr><th colspan="5">Omgångens matcher</th></tr>
                </thead>
                <tbody>
                  {#each game.upcoming as event, i}
                    <tr>
                      <td>{i + 1}.</td>
                      <td class="event">
                        <span>{event.event}</span>
                        <span>Odds</span>
                        <span>Svenska folket</span>
                      </td>
                      {#each ["1", "X", "2"] as outcome, i}
                        <td>
                          <span>{outcome}</span>
                          <span>{event.odds[outcome].odds}</span>
                          <span>{event.odds[outcome].folket}</span>
                        </td>
                      {/each}
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="5">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
                </tbody>
              </table>
            </div>
          {:else if game.name === 'Oddset Powerplay'}
            <div class="oddset-results">
              <table class="results">
                <thead>
                  <tr>
                    <th colspan="4">Rätt rad</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.results as event, i}
                  {@const game_result = Number(event.score.split(" - ")[0]) > Number(event.score.split(" - ")[1]) ? "1" : Number(event.score.split(" - ")[0]) < Number(event.score.split(" - ")[1]) ? "2" : "X"}
                    <tr>
                      <td class="order">{i + 1}.</td>
                      <td class="event">{event.event}</td>
                      <td class="score"><span>{event.score}</span></td>
                      <td class="result">{game_result}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
              <table class="winners">
                <thead>
                  <tr>
                    <th>Antal rätt</th><th>Antal vinnare</th><th>Utdelning</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.winners as event, i}
                    <tr>
                      <td>{event.result}</td>
                      <td>{numberSpacing(event.winners)} st</td>
                      <td>{numberSpacing(event.prize)} kr</td>
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="3">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
              </table>
            </div>
          {:else if game.name === 'Oddset Challenge'}
            <div class="oddset-results">
              <table class="results">
                <thead>
                  <tr>
                    <th colspan="4">Rätt rad</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.results as event, i}
                  {@const game_result = Number(event.score.split(" - ")[0]) > Number(event.score.split(" - ")[1]) ? "1" : Number(event.score.split(" - ")[0]) < Number(event.score.split(" - ")[1]) ? "2" : "X"}
                    <tr>
                      <td class="order">{i + 1}.</td>
                      <td class="event">{event.event}</td>
                      <td class="score"><span>{event.score}</span></td>
                      <td class="result">{game_result}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
              <table class="winners">
                <thead>
                  <tr>
                    <th>Antal rätt</th><th>Antal vinnare</th><th>Utdelning</th>
                  </tr>
                </thead>
                <tbody>
                  {#each game.winners as event, i}
                    <tr>
                      <td>{event.result}</td>
                      <td>{numberSpacing(event.winners)} st</td>
                      <td>{numberSpacing(event.prize)} kr</td>
                    </tr>
                  {/each}
                  <tr>
                    <td colspan="3">Omsättning: {numberSpacing(game.turnover)} kr</td>
                  </tr>
              </table>
            </div>
          {:else if game.name === 'Oddset Bomben'}
            <div class="oddset-results">
              {#each game.results as coupon, i}
                <table class="results">
                  <thead>
                    <tr>
                      <th colspan="3">Resultat Bomben {i + 1}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {#each coupon.results as event, j}
                      <tr>
                        <td class="order">{j + 1}.</td>
                        <td class="event">{event.event}</td>
                        <td class="score"><span>{event.score}</span></td>
                      </tr>
                    {/each}
                    <tr>
                      <td colspan="2">Omsättning: {numberSpacing(coupon.turnover)} kr</td>
                      <td>Odds: {numberSpacing(coupon.odds)}</td>
                    </tr>
                  </tbody>
                </table>
              {/each}
            </div>
          {:else if game.name === 'Oddset Matchen'}
            <div class="oddset-results">
              {#each game.results as coupon, i}
                <table class="results">
                  <thead>
                    <tr>
                      <th colspan="3">Resultat Matchen {i + 1}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {#each coupon.results as event, j}
                      <tr>
                        <td class="order">{j + 1}.</td>
                        <td class="event">{event.event}</td>
                        <td class="score"><span>{event.score}</span></td>
                      </tr>
                    {/each}
                    <tr>
                      <td colspan="2">Omsättning: {numberSpacing(coupon.turnover)} kr</td>
                      <td>Odds: {numberSpacing(coupon.odds)}</td>
                    </tr>
                  </tbody>
                </table>
              {/each}
            </div>
          {/if}
        </div>

        <div class="box-footer">
          <a href={game.operator.url} class="cta">
            <span>{game.cta}</span>
            <span>{game.operator.name}</span>
          </a>
          <i><Terms operator={game.operator} /></i>
        </div>
      </div>

      {#if props.linkAnchor && props.linkUrl}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            <a class="more" href={props.linkUrl}>{props.linkAnchor}</a>
          </div>
        </div>
      {/if}
    </Block>
  {/if}
{/if}