<script>
  import MatchCenter from "$lib/admin/components/blocks/testing/MatchCenter.svelte";
  import BettingTips from "$lib/admin/components/blocks/testing/BettingTips.svelte";
  import OddsList from "$lib/admin/components/blocks/testing/OddsList.svelte";
  import Banner from "$lib/admin/components/blocks/testing/Banner.svelte";
  import Newsletter from "$lib/admin/components/blocks/testing/Newsletter.svelte";

  export let adminView = false;
  export let match = {
    "home_team": {
      "name": "India",
      "code": "IND"
    },
    "away_team": {
      "name": "Australia",
      "code": "AUS"
    },
    "sport_seasons": {
      "name": "Tour"
    }
  }
</script>

{#if adminView}
  <p>Template for single cricket betting tips.</p>
{:else}
  <MatchCenter {match} />

  <BettingTips props={ {bettingTipsType: "event" } }/>

  <OddsList {match} props={ { oddsListType: "slider"}}/>

  <Banner props={ {bannerType: "betting-offer" } } />

  <!-- TODO: move to regular `content` component -->
  <div class="block block-content">
    <div class="container">
      <div class="grid">
        <div class="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
  <div class="tip-analysis-text">
    <div class="row">
      <h2>England vs India Match Prediction & Analysis</h2>
      <ul class="key-points">
        <li>One point</li>
        <li>Another point</li>
        <li>The last and greatest point</li>
      </ul>
      <p>The Royal London One Day Cup is taking a bit backstage at the moment due to the advent of international cricket. Another popular competition is The Hundred that is going on at the moment. However, the English cricketers are showing their capability and making the cricketing audience take a keen interest in the one-day matches. Durham managed to record their first victory of the competition yesterday after suffering losses in the initial games. Both these teams will be keen to retain their winning ways and move up the points table.</p>

      <p>In the last match, Durham scored a solid 342 runs from their quota of 50 overs. Graham Clark with 80 runs and Scot Borthwick with 88 runs hit valiant fifties to lead the Durham charge in the match at the top. There were some useful cameos played by Bushnell, Liam Trevaskis, and Macintosh in the middle order that allowed Durham to set a massive score on the board.</p>

      <p>The Durham bowling department held onto their nerves in the match. They were successful in bowling out the opposition for 333 runs. Durham won the match by 9 runs in the end. Alridge and J Brooks took three wickets a piece in the game while 4 other bowlers picked up a wicket a piece. But the team will be looking forward to a better bowling display in this encounter. The key players for Durham in this match are Scott Borthwick, Liam Trevaskis, and J Brooks.</p>

      <div class="tip-meta">
        <img alt="dhruv gupta" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_100/varial/ocb/assets/about/dhruv-g.png">
        <span>Written by <a href={null}>A writer</a> on 4 Aug 2022</span>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>

  <Newsletter />
{/if}