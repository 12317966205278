<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Section styling"
      id="styling"
      bind:value={props.sectionStyling}
      on:change>
      <option value="">None</option>
      <option value="section contrast">Contrast</option>
      <option value="section colored">Colored</option>
      <option value="section contrast texture">Contrast + Texture</option>
      <option value="section colored texture">Colored + Texture</option>
    </Input>
  </Group>
{/if}