<script>
  import { createEventDispatcher } from "svelte";
  import { project, previewActiveModal } from "$lib/services/store";
  import supabase from "$lib/services/db";
  import Cards from "$lib/admin/components/Cards.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import Manage from "$lib/admin/components/Manage.svelte";
  import ImageBrowser from "$lib/admin/components/media/ImageBrowser.svelte";

  export let imageUrl = null;

  $: imageFolders = $project?.data?.imageFolders || {}
  let toggleUploadForm = false;
  let toggleBrowseForm = false;
  let imagePath = null;
  let imageName = null;
  let uploading = false;
  let files;
  let message;

  const dispatch = createEventDispatcher();

  const getImageURL = async () => {
    const { data, error } = await supabase.storage
      .from("content")
      .getPublicUrl(imagePath);

    if (error) {
      console.error(error);
      message = { success: false, display: error };
    } else {
      return data.publicUrl;
    }
  };

  const getImageName = () => {
    const file = getSelectedFile();
    const fileExt = file.name.split(".").pop();
    const fileName = imageName ? imageName : file.name;
    return fileName;
  };

  const getSelectedFile = () => {
    if (!files || files.length === 0) {
      throw new Error("You must select an image to upload.");
    }
    return files[0];
  };

  const buildImagePath = () => {
    const imageName = getImageName();
    return `${$project.key}/images/media/${imageName}`;
  };

  const uploadImage = async () => {
    try {
      uploading = true;
      message = null;

      const file = getSelectedFile();
      const filePath = buildImagePath();
      let { error: uploadError } = await supabase.storage
        .from("content")
        .upload(filePath, file);

      if (uploadError) {
        console.error(uploadError);
        if (uploadError.error === "Duplicate")
          uploadError.message += "\nTry changing the image name.";
        message = { success: false, display: uploadError.message };
        return;
      }
      message = { success: true, display: "Image uploaded successfully!" };
      imagePath = filePath;

      dispatch("upload");
      imageUrl = file.name
      console.log(imageUrl)
      dispatch("keyup");

      // Close Modal form only when successful, maybe image name was already
      // taken, so needs to keep open so user can change it.
      toggleUploadForm = false;
    } catch (error) {
      message = { success: false, display: error.message };
    } finally {
      uploading = false;
    }
  };
</script>

<!-- Image display if uploaded -->
<Group addClass="image-upload">
  <div class="image-holder flex-c-c">
    {#if imageUrl}
      <!-- svelte-ignore a11y-missing-attribute -->
      <img
        loading="lazy"
        data-src={ imageFolders.media + imageUrl}
        src={ imageFolders.media + imageUrl} />
      <Button title="Remove current image" onlyIcon icon="trash-2" on:click={() => imageUrl = ""}/>
      <span class="image-url">{imageFolders.media + imageUrl}</span>
    {:else}
      <span>No image found. Upload or browse for an image.</span>
    {/if}
  </div>
</Group>

<!-- Image already uploaded, can only adjust alt text and caption -->
{#if !imageUrl}
  <Group>
    <Button on:click={() => {toggleBrowseForm = true; $previewActiveModal = true;}}>Browse</Button>
    <!-- TODO: add 'accept="image/*"' to Input component? -->
    <Input
      id="upload-image"
      type="file"
      placeholder="Upload image"
      bind:files
      on:change={() => {
        // Possible user hit cancel on file selection, so then no image name on toggle
        // so dont open Modal
        try {
          imageName = getImageName();
          toggleUploadForm = !toggleUploadForm;
        } catch {}
      }}
      disabled={uploading}
    />
  </Group>
{/if}

<!-- Modal form to set initial image, alt and caption -->
<Modal bind:toggleModal={toggleUploadForm}>
  <svelte:fragment slot="header">Image details</svelte:fragment>
  <Cards type="form">
    <Group colspan="4-8">
      <Input
        label="Image name"
        placeholder="Image name"
        id="image-name"
        type="text"
        bind:value={imageName}
      />
    </Group>
    <Group addClass="form-item inline">
      <Manage {message} />
      <Button on:click={uploadImage} addClass="secondary" icon="plus-square"
        >Upload</Button
      >
    </Group>
  </Cards>
</Modal>

<!-- Modal for browsing and selecting from existing images -->
<Modal bind:toggleModal={toggleBrowseForm}>
  <svelte:fragment slot="header">Browse Images</svelte:fragment>
  <ImageBrowser
    allowDeletes={false}
    imageSelectorCallback={(img) => {
      imageName = img.name;
      imageUrl = img.name;
      toggleBrowseForm = false;
    }}
  />
</Modal>

<style>
  .image-holder {
    background-color: var(--bg-clr);
    border-radius: var(--bd-rad);
    padding: 1rem;
    position: relative;
    display: block;
  }
  .image-holder img {
    max-height: 10rem;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
    margin: 0 auto;
  }
  .image-holder span.image-url {
    margin: 0;
    font-size: 0.5rem;
    color: #999;
    text-align: center;
    display: block;
    word-wrap: break-word;
    overflow: hidden;
  }
  .image-holder span {
    margin: 1rem;
  }
  :global(.image-holder button) {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
</style>
