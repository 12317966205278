<script>
  import { monthNumberToMonthLetters } from "$lib/services/utils";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Heading from '$lib/admin/components/blocks/helpers/Heading.svelte';
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import { project, translations } from '$lib/services/store';

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Heading {props} {adminView} />

  <Group addClass="form-item" colspan="1-11">
    <Input
      label="Focus panel design"
      type="checkbox"
      bind:checked={props.archiveFocusPanel}
      on:change />
  </Group>
{:else}
  <Block
    blockClass={"block-archive-news" + (props.archiveFocusPanel ? " block-focus-panel" : "")}
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
    <Heading {props} />

    {#if props.months}
      {#each Object.keys(props.months).sort().reverse() as year}
        <h4>{year}</h4>
        <ul>
          {#each props.months[year] as month, i}
          <li>
            <a
              id={`${month}-${year}`}
              href={`/${$translations["News"][$project.settings.lang].toLowerCase()}/${$translations["Archive"][$project.settings.lang].toLowerCase()}/${$translations[monthNumberToMonthLetters(Number(month), true)][$project.settings.lang].toLowerCase()}-${year}`}><Translate text={monthNumberToMonthLetters(Number(month), true)} /> {year}</a>
          </li>
          {/each}
        </ul>
      {/each}
    {/if}
  </Block>
{/if}