<script>
  import { project, orgOperators, translations } from '$lib/services/store';
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte";
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import { createEventDispatcher } from "svelte";
  import CurrencyAmount from '$lib/admin/components/blocks/helpers/CurrencyAmount.svelte';
  import Terms from '$lib/admin/components/blocks/helpers/Terms.svelte';


  export let props = null;
  export let adminView = false;

  let selectedTab = "casino";

  const dispatch = createEventDispatcher();
</script>

{#if adminView}
  <Group colspan="2" addClass="form-item">
    <Group>
      <Input
        label="Type"
        type="select"
        bind:value={props.bonusType}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        <option value="bonus">Bonus</option>
        <option value="small">Small</option>
        <option value="review">Review</option>
        <option value="single-table">Single table</option>
        <option value="bonus-boxes">Bonus boxes</option>
      </Input>
    </Group>
    <Group>
      <Input
        label="Operator"
        type="select"
        bind:value={props.operatorId}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        {#each $orgOperators as operator}
          <option value={operator.id}>{operator.name ?? "Couldn't find metadata"}</option>
        {/each}
      </Input>
    </Group>
  </Group>
  {#if props.bonusType != "single-table"}
    <Group addClass="form-item">
      <SunEditorForm bind:text={props.content} placeholder="Review text" on:keyup />
    </Group>
  {/if}
{:else}
  {#if props.bonusType == 'bonus'}
    <div class="row">
      <div class="brand bonus">
        <a class={"logo " + props.metadata?.nameClass} href="/betting-sites/{props.metadata?.nameLower}">
          <img
            class="lazyload"
            loading="lazy"
            alt={props.metadata?.name}
            src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=80&auto=format"}
            data-src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=80&auto=format"}>
        </a>
        <div class="offer">
          <h4>Betting Offer</h4>
          {#if props.content}
            <p>{@html props.content}</p>
          {:else}
            <p>Bet ₹1,000 and Get a ₹3,000 Free Bet</p>
          {/if}
        </div>
        <a class="visit" href={`/${$project.settings.redirectUrl}/${props.metadata?.nameLower}`} target="_blank" rel="nofollow noreferrer">Claim</a>
      </div>
    </div>
  {:else if props.bonusType == 'review'}
    <div class="row">
      <div class={"brand review " + props.metadata?.nameClass}>
        <img
          class="lazyload"
          loading="lazy"
          alt={props.metadata?.name}
          src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=80&auto=format"}
          data-src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=80&auto=format"}>
        <div class="info">
          {#if props.content}
            {@html props.content}
          {/if}
        </div>
        <a href={`/${$project.settings.redirectUrl}/${props.metadata?.nameLower}`}>Besøg {props.metadata?.name}</a>
        <a href={"/" + $project.data.categoryPages?.reviews?.slug + "/" + props.metadata?.nameLower}>Læs anmeldelse</a>
      </div>
    </div>
  {:else if props.bonusType == "single-table"}
    {@const operator = $orgOperators?.find(obj => obj.id === props.operatorId)}
    {#if operator
      && !operator?.rating[$project.key][$project.settings.market]["closed"]}
      <Toplist props={ {
        "toplistOperatorId": props.operatorId,
        "toplistType": "table",
        "toplistFocus": "casino",
        "toplistLength": "1" } }/>
    {/if}
  {:else if props.bonusType == "bonus-boxes"}
    {#if props.operatorId && $orgOperators}
      {@const operator = $orgOperators.find(x => x.id == props?.operatorId)}
      {#if operator?.bonus.casino[$project.settings.market].bonus_amount
        || (operator?.bonus.casino[$project.settings.market].freespins_amount && operator?.bonus.casino[$project.settings.market].freespins_amount > 0)
        || operator?.bonus.sports[$project.settings.market].bonus_amount}
        <Block
          blockClass="block-tabbed-tables bonus-boxes"
          gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
          {props}>
          <div class="tab-section">
            <ul class="tabs">
              {#if operator.bonus.casino[$project.settings.market]
                && (operator.bonus.casino[$project.settings.market].bonus_amount || operator.bonus.casino[$project.settings.market].freespins_amount)}
                <li
                  class:active={selectedTab == "casino"}>
                  <button
                    class="icon"
                    on:click={() => {
                      selectedTab = "casino";
                    }}><img loading="lazy" alt={`${operator.name} Casino Bonus`} src={`${$project.data.imageFolders.icons}/casino-roulette.png?auto=format`}> <Translate text="Casino bonus" /></button>
                </li>
              {/if}
              {#if operator.bonus.sports[$project.settings.market]
                && operator.bonus.sports[$project.settings.market].bonus_amount}
                <li
                  class:active={selectedTab == "sports"}>
                  <button
                    class="icon"
                    on:click={() => {
                      selectedTab = "sports";
                    }}><img loading="lazy" alt={`${operator.name} Sports Bonus`} src={`${$project.data.imageFolders.icons}/odds-betting.png?auto=format`}> <Translate text="Sports bonus" /></button>
                </li>
              {/if}
            </ul>

            <table class:show={selectedTab === "casino"}>
              <tbody>
                {#if operator.bonus.casino[$project.settings.market]["bonus_amount"]}
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt="Casino Bonus"
                        src={`${$project.data.imageFolders.icons}/casinobonus.png?auto=format`}>
                      <Translate text="Casino bonus" />
                    </td>
                    <td><CurrencyAmount amount={operator.bonus.casino[$project.settings.market].bonus_amount} /></td>
                  </tr>
                {/if}
                {#if operator.bonus.casino[$project.settings.market]["freespins_amount"]
                  && operator.bonus.casino[$project.settings.market]["freespins_amount"] != 0}
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={operator.bonus.casino[$project.settings.market]?.["freespins_type"] ? operator.bonus.casino[$project.settings.market]?.["freespins_type"] : "Freespins"}
                        src={`${$project.data.imageFolders.icons}/freespins.png?auto=format`}>
                      {operator.bonus.casino[$project.settings.market]?.["freespins_type"] ? operator.bonus.casino[$project.settings.market]?.["freespins_type"] : "Freespins"}
                    </td>
                    <td>{operator.bonus.casino[$project.settings.market]["freespins_amount"]} st</td>
                  </tr>
                {/if}
                {#if operator.bonus.casino[$project.settings.market]["bonus_amount"]}
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={$translations["Wagering req."][$project.settings.lang]}
                        src={`${$project.data.imageFolders.icons}/omskrav.png?auto=format`}>
                      <Translate text="Wagering req." /> bonus
                    </td>
                    <td>{operator.bonus.casino[$project.settings.market].bonus_turnover}x</td>
                  </tr>
                {/if}
                {#if operator.bonus.casino[$project.settings.market]["freespins_amount"]
                  && operator.bonus.casino[$project.settings.market]["freespins_amount"] != 0}
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={`${$translations["Wagering req."][$project.settings.lang]} freespins`}
                        src={`${$project.data.imageFolders.icons}/omskrav.png?auto=format`}>
                      <Translate text="Wagering req." /> freespins
                    </td>
                    <td>{operator.bonus.casino[$project.settings.market]["freespins_turnover"] ?? 0}x</td>
                  </tr>
                {/if}
              </tbody>
            </table>
            <table class:show={selectedTab === "sports"}>
              <tbody>
                <tr>
                  <td class="icon">
                    <img
                      loading="lazy"
                      alt="Sports bonus"
                      src={`${$project.data.imageFolders.icons}/casinobonus.png?auto=format`}>
                    <Translate text="Sports bonus" />
                  </td>
                  <td>{operator.bonus.sports[$project.settings.market].bonus_percent ? `${operator.bonus.sports[$project.settings.market].bonus_percent}% ${$translations["Up to"][$project.settings.lang].toLowerCase()} ` : ""}<CurrencyAmount amount={operator.bonus.sports[$project.settings.market].bonus_amount} /></td>
                </tr>
                <tr>
                  <td class="icon">
                    <img
                      loading="lazy"
                      alt={`${$translations["Wagering req."][$project.settings.lang]}`}
                      src={`${$project.data.imageFolders.icons}/omskrav.png?auto=format`}>
                    <Translate text="Wagering req." /> <Translate text="Sports bonus" />
                  </td>
                  <td>{operator.bonus.sports[$project.settings.market].bonus_turnover}x</td>
                </tr>
                {#if operator.bonus.sports[$project.settings.market].bonus_min_odds
                  && operator.bonus.sports[$project.settings.market].bonus_min_odds != "-"}
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={`${$translations["Min. odds"][$project.settings.lang]}`}
                        src={`${$project.data.imageFolders.icons}/min-odds.png?auto=format`}>
                      <Translate text="Min. odds" />
                    </td>
                    <td>{operator.bonus.sports[$project.settings.market].bonus_min_odds}</td>
                  </tr>
                {/if}
              </tbody>
            </table>

            {#if selectedTab == "casino"}
              <a class="cta" target="_blank" rel="nofollow" href={`/${$project.settings.redirectUrl}/${operator.name_lower}`}>
                <span><Translate text="To" /> {operator.name}</span>
                <span>Casino</span>
              </a>
              <i><Terms {operator} /></i>
            {:else}
              <a class="cta" target="_blank" rel="nofollow" href={`/${$project.settings.redirectUrl}/${operator.name_lower}`}>
                <span><Translate text="To" /> {operator.name}</span>
                <span>Sport</span>
              </a>
              <i><Terms {operator} focus="sports" /></i>
            {/if}
          </div>
        </Block>
      {/if}
    {/if}
  {:else}
    <p>Not found</p>
  {/if}
{/if}