<script>
  export let adminView = false;
</script>

{#if adminView}
  <p>Newsletter</p>
{:else}
  <div class="block block-newsletter">
    <div class="container">
      <div class="grid">
        <div class="g-col-12">
          <div class="newsletter-signup-box">
            <h4>OCB Newsletter</h4>
            <div class="content">
              <p>Get all the latest match predictions, betting tips, betting site reviews and exclusive promotions with OCB - right in your email inbox!</p>
              <form>
                <input placeholder="Enter your email">
                <button>Subscribe today</button>
              </form>
              <i>18+ Only.</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .newsletter-signup-box {
    margin: 0 0 1rem 0;
    background: var(--bg-clr-alt);
    border-radius: var(--bd-rad);
  }
  .newsletter-signup-box h4 {
    font-size: 1rem;
    margin: 0;
    background: #232323;
    padding: 1rem;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    border-radius: var(--bd-rad) var(--bd-rad) 0 0;
  }
  .newsletter-signup-box h4:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mail'%3E%3Cpath d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'%3E%3C/path%3E%3Cpolyline points='22,6 12,13 2,6'%3E%3C/polyline%3E%3C/svg%3E");
    line-height: 0;
    margin: 0 0.5rem 0 0;
  }
  .newsletter-signup-box .content {
    padding: 1rem;
  }
  .newsletter-signup-box p {
    font-size: 0.75rem;
    font-weight: 500;
  }
  .newsletter-signup-box form {
    display: grid;
    gap: 0.5rem;
  }
  .newsletter-signup-box form input {
    padding: 0.5rem;
    border-radius: var(--bd-rad);
    border: 2px solid #12171c;
    font-size: 0.875rem;
  }
  .newsletter-signup-box form button {
    text-align: center;
    font-weight: 600;
    font-size: 0.875rem;
    background: #12171c;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: var(--bd-rad);
    cursor: pointer;
  }
  .newsletter-signup-box form button:hover {
    background: #212f3d;
  }
  .newsletter-signup-box i {
    color: #999;
    font-style: italic;
    text-align: center;
    width: 100%;
    margin: 0.25rem 0 0 0;
    display: inline-block;
    font-size: 0.625rem;
  }
</style>