<script>
  import { orgOperators } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
	import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;
  const dispatch = createEventDispatcher();

  let operator = $orgOperators.find(x => x.name === props.operator) ?? $orgOperators[5]

  // TEMP:
  let dummyData = [
    {
      "name": "WinDaddy",
      "nameLower": "windaddy",
      "offer": {
        "type": "Welcome Bonus",
        "amount": "₹11,000",
        "match": "100%"
      }
    },
    {
      "name": "Dafabet",
      "nameLower": "dafabet",
      "offer": {
        "type": "Welcome Bonus",
        "amount": "₹17,000",
        "match": "170%"
      }
    },
    {
      "name": "Parimatch",
      "nameLower": "parimatch",
      "offer": {
        "type": "Welcome Bonus",
        "amount": "₹30,000",
        "match": "150%"
      }
    },
    {
      "name": "Fun88",
      "nameLower": "fun88",
      "offer": {
        "type": "Welcome Bonus",
        "amount": "₹1,00,000",
        "match": "450%"
      }
    },
    {
      "name": "Betway",
      "nameLower": "betway",
      "offer": {
        "type": "Free Bet",
        "amount": "₹4,000",
        "match": "100%"
      }
    }
  ]

  let showOffer = false;
  let popupOffer = null;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Banner Type"
      type="select"
      bind:value={props.bannerType}
      on:change={() => dispatch("keyup")}>
      <option value="">~not set~</option>
      <option value="free-bets">Free Bets</option>
      <option value="new-customer">New Customer</option>
      <option value="betting-offer">Betting Offer</option>
      <option value="brought-by">Brought by</option>
      <option value="slider">Offer slider</option>
      <!-- TODO: add option for an actual image banner-->
    </Input>
  </Group>
{:else}
  {#if props.bannerType == 'free-bets'}
    <div class="banner">
      <div class="row">
        <a href={null}>
          <h4>Free Bets</h4>
          <span class="cta">Get offers</span>
        </a>
      </div>
    </div>
  {:else if props.bannerType == 'new-customer'}
    <div class="new-customer">
      <div class="row">
        <a href={null} class="_1xbet">
          <img loading="lazy" alt="1xbet" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_50/varial/ocb/betting-sites/1xbet-icon">
          <span class="tag">New Customer Offer</span>
          <span class="offer">130% Welcome Bonus up to ₹26,000</span>
        </a>
      </div>
    </div>
  {:else if props.bannerType == 'betting-offer'}
    <div class="block block-betting-offer">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="betting-offer _bet365">
              <div class="betting-offer-header">
                <a href={null}><img loading="lazy" alt="Bet365" src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_150/varial/ocb/betting-sites/bet365-logo"></a>
                <span>Bet ₹1,000 - Get ₹5,000 in free bets!</span>
              </div>
              <div class="row">
                <p>Create a new betting account with Bet365 and place your first bet to receive up to ₹5,000 in free bets!</p>
                <a href={null}>Claim offer</a>
                <i>For new customers. Min deposit requirement. Free Bets are paid as Bet Credits and are available for use upon settlement of qualifying bets. Min odds, bet and payment method exclusions apply. Returns exclude Bet Credits stake. Time limits and T&Cs apply.</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else if props.bannerType == 'brought-by'}
    <div class={ "block block-brought-by _" + operator.name_lower}>
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="banner">
              <span>Brought to you by</span>
              <img loading="lazy" alt={ operator.name } src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_100/varial/ocb/betting-sites/{operator.name_lower}-icon">
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else if props.bannerType == 'slider'}
    <div class="block block-offers">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            <div class="slider">
              {#each dummyData as item, i}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                  class={ "offer-item _" + item.nameLower}
                  on:click={() => {popupOffer = item; showOffer = !showOffer;}}>
                  <span class="offer-operator">{item.name} {item.offer.type}</span>
                  <img loading="lazy" alt={ item.name + " " + item.offer.type } src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_200/varial/ocb/betting-sites/{item.nameLower}-icon">
                  <div class="offer-details">
                    <span>up to</span>
                    <span>{item.offer.amount}</span>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>

      <Modal bind:toggleModal={showOffer} removeHeader addClass={ "_" + popupOffer?.nameLower}>
        <div class="popup-offer">
          <h4>{popupOffer.name} {popupOffer.offer.type}</h4>
          <div class={ "popup-offer-brand" + " _" + popupOffer.nameLower }>
            <img loading="lazy" alt={ popupOffer.name + " " + popupOffer.offer.type } src="https://res.cloudinary.com/qm-media/image/upload/f_auto,w_200/varial/ocb/betting-sites/{popupOffer.nameLower}-icon">
          </div>
          <p>Get {popupOffer.offer.match} up to {popupOffer.offer.amount} {popupOffer.offer.type.toLowerCase()} with {popupOffer.name}!</p>
          <a href={null} class="popup-offer-claim">Claim Offer</a>
        </div>
      </Modal>
    </div>
  {/if}
{/if}

<style>
  .banner a {
    background: #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0 2rem 0;
    padding: 0.75rem;
    background: linear-gradient(130deg, rgba(51,51,51,1) 29%, rgba(0,0,0,1) 63%, rgba(51,51,51,1) 100%);
    background: linear-gradient(130deg, rgba(244,156,35,1) 55%, rgba(209,120,13,1) 55%, rgba(209,120,13,1) 100%);
    transition: all ease-in-out .2s;
  }
  .banner h4 {
    font-size: 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .banner h4:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-gift'%3E%3Cpolyline points='20 12 20 22 4 22 4 12'%3E%3C/polyline%3E%3Crect x='2' y='7' width='20' height='5'%3E%3C/rect%3E%3Cline x1='12' y1='22' x2='12' y2='7'%3E%3C/line%3E%3Cpath d='M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z'%3E%3C/path%3E%3Cpath d='M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z'%3E%3C/path%3E%3C/svg%3E");
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0 0;
  }
  .banner .cta {
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  }
  .banner .cta:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.25rem;
    line-height: 0;
    transition: all ease-in-out .2s;
  }
  .banner a:hover {
    filter: brightness(102%);
  }
  .banner a:hover .cta:after {
    margin: 0 0.1rem 0 0.4rem;
  }

  .new-customer a {
    display: grid;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    gap: 0.25rem 0;
    align-items: center;
    grid-template-columns: 2.5rem 1fr 1rem;
    grid-template-areas:
      "logo tag arrow"
      "offer offer arrow";
    margin: 0 0 1rem 0;
    transition: all ease-in-out .2s;
  }
  .new-customer a:hover {
    opacity: 0.95;
  }
  .new-customer a:after {
    content: "\203A";
    grid-area: arrow;
    color: #fff;
    transition: all ease-in-out .2s;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .new-customer a:hover:after {
    padding: 0 0 0 0.25rem;
  }
  .new-customer a img {
    grid-area: logo;
    width: 2rem;
    border-radius: 4px;
  }
  .new-customer a .tag {
    grid-area: tag;
    font-size: 0.55rem;
    background: rgba(255,255,255,0.2);
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.1rem 0.35rem;
    border-radius: 4px;
    justify-self: baseline;
  }
  .new-customer a .offer {
    grid-area: offer;
    font-size: 0.75rem;
    font-weight: bold;
    color: #fff;
  }

  /* BETTING OFFER */
  .block-betting-offer .betting-offer-header {
    display: grid;
    grid-auto-flow: column;
    padding: 1rem;
    align-items: center;
    grid-template-columns: 4.5rem auto;
  }
  .block-betting-offer .betting-offer-header a {
    display: block;
  }
  .block-betting-offer .betting-offer-header a img {
    width: 4rem;
    display: block;
  }
  .block-betting-offer .betting-offer-header span {
    font-size: 0.875rem;
    margin: 0;
    color: #fff;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
  }
  .block-betting-offer .betting-offer .row {
    background-color: #168e6c;
    display: inline-grid;
    gap: 0.5rem;
    padding: 1rem;
  }
  .block-betting-offer .betting-offer p {
    color: #fff;
    font-size: 0.75rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
  }
  .block-betting-offer .betting-offer .row a {
    background: #ffdf1a;
    color: #000;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    font-weight: 700;
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
  }
  .block-betting-offer .betting-offer .row a:hover {
    background: #e4e4e4;
  }
  .block-betting-offer .betting-offer .row i {
    font-size: 0.6rem;
    color: #ddd;
    text-align: center;
    font-weight: 400;
  }

  /* BROUGHT BY */
  .block-brought-by {
    margin: 0; /* TEMP: */
  }
  .block-brought-by .banner {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    font-size: 0.65rem;
    font-weight: 600;
    padding: 0.75rem;
  }
  .block-brought-by .banner img {
    width: 1.5rem;
    margin: 0 0 0 0.25rem;
  }

  /* OFFER SLIDER */
  .block-offers .offer-item {
    border-radius: 4px;
    display: grid;
    grid-auto-flow: column;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    grid-template-areas:
      "name name"
      "img details";
    grid-template-columns: 3rem calc(100% - 3rem);
    transition: all 0.2s ease-in-out;
  }
  .block-offers .offer-item:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  .block-offers .offer-item span.offer-operator {
    grid-area: name;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .block-offers .offer-item img {
    grid-area: img;
    display: block;
    height: 3rem;
  }
  .block-offers .offer-item .offer-details {
    grid-area: details;
    font-size: 0.5rem;
    display: grid;
  }
  .block-offers .offer-item .offer-details span {
    display: block;
    width: 100%;
    text-align: center;
    align-self: flex-end;
  }
  .block-offers .offer-item .offer-details span:last-of-type {
    align-self: flex-start;
    font-size: 1rem;
    font-weight: 600;
  }
  .block-offers .popup-offer {
    display: grid;
    gap: 1rem;
  }
  .block-offers .popup-offer h4 {
    text-align: center;
    text-transform: uppercase;
  }
  .block-offers .popup-offer .popup-offer-brand {
    height: 75px;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 auto;
  }
  .block-offers .popup-offer .popup-offer-brand img {
    object-fit: contain;
    height: 100%;
    padding: 0.5rem 3rem;
  }
  .block-offers .popup-offer p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 1rem 0;
  }
  .block-offers .popup-offer .popup-offer-claim {
    background: green;
    color: #fff;
    max-width: 200px;
    padding: 0.5rem 3rem;
    border-radius: 4px;
    margin: 0 auto;
    font-size: 0.75rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  }
  .block-offers .popup-offer .popup-offer-claim:hover {
    background: #009b00;
  }
</style>